site_config = $.extend(true, {
	"posts": {
		"db": true,
		"cake": true,
		"sendy": false,
		"expert_sender": false,
		"exact_target": true,
		"blue_hornet": false,
		"trusted_form": true,
		"twilio": true
	},
	"cake": {
		"post_url": "http:\/\/suited45trk.com\/d.ashx?",
		"posts": {
			"mortgage": {
				"post_type": "session",
				"ckm_key": "CJQfrRQ50Ag",
				"payable_criteria": {
					"refinance": {
						"loan_purpose": [
							"Refinance",
							"HARP Refinance"
						],
						"credit_rating": [
							"average",
							"good",
							"excellent"
						],
						"mortgage_amount": [
							">=102500"
						]
					}
				}
			},
			"home_improvement": {
				"post_url": "http:\/\/suited45trk.com\/d.ashx?",
				"post_type": "server",
				"ckm_campaign_id": 1756,
				"ckm_key": "eXeYdx0VhHM",
				"post_criteria": {
					"home_services": {
						"trusted_pro": [
							"Yes"
						]
					}
				}
			},
			"home_security": {
				"post_url": "http:\/\/suited45trk.com\/d.ashx?",
				"post_type": "server",
				"ckm_campaign_id": 1017,
				"ckm_key": "tjxqMZMb0U",
				"post_criteria": {
					"home_alert": {
						"home_security": [
							1
						]
					}
				}
			},
			"home_solar": {
				"post_url": "http:\/\/suited45trk.com\/d.ashx?",
				"post_type": "server",
				"ckm_campaign_id": 1147,
				"ckm_key": "pyhfnBmeqvw",
				"post_criteria": {
					"solar_alert": {
						"solar": [
							1
						]
					}
				}
			},
			"real_estate": {
				"post_url": "http:\/\/suited45trk.com\/d.ashx?",
				"post_type": "server",
				"ckm_key": "IrnnyK1CNxw",
				"ckm_campaign_id": 2531,
				"post_criteria": {
					"refinance": {
						"loan_purpose": [
							"Purchase"
						]
					}
				}
			}
		},
		"request_id_params": {
			"affiliate": 52,
			"additional_params": ""
		}
	},
	"bing": {
		"should_include_script": false
	},
	"visual_web_optimizer": {
		"account_id": 37268
	},
	"leadpoint": {
		"should_show_disclosure": false,
		"account_id": 35209,
		"channel_id": 17734
	},
	"google_remarketing_tag": {
		"conversion_id": 979222320
	},
	"google_analytics": {
		"do_step_level_tracking": true,
		"optimizely": false
	},
	"popunder": {
		"show": false,
		"type": "precisionads",
		"precisionads": {
			"seller_id": 35209,
			"sub_id": 177,
			"cid": null,
			"client_url": null
		},
		"quinstreet": {
			"client_id": 602864
		},
		"vantagemedia": {
			"display_id": 13444,
			"publisher_id": 36365,
			"adsource": "organic",
			"engagement_option": 1,
			"max_results": 8
		},
		"homeimprovement": {
			"img_url": null,
			"message_type": "home improvement"
		},
		"clicksnet": {
			"affcid": 1064054,
			"refi_affcid": 1072062,
			"key": "ybWJODkxs581",
			"refi_key": "hY13n3BkTBk1"
		}
	},
	"site_flow": {
		"next_urls": {
			"default": "\/thankyou"
		}
	},
	"help": {
		"phone_number_dial": null,
		"phone_number_display": null
	},
	"sendy": {
		"api_key": "lnxZhxc3rWAHSF4929Vm",
		"installation_url": "http:\/\/send.lenderqualified.com",
		"lists": {
			"purchase": {
				"credit": {
					"excellent": [
						"NYq5or8h6N4VCcinreAz4A",
						"adtkd86RR763dGuYP7nGIq763Q"
					],
					"good": [
						"NYq5or8h6N4VCcinreAz4A",
						"adtkd86RR763dGuYP7nGIq763Q"
					],
					"average": [
						"NYq5or8h6N4VCcinreAz4A",
						"adtkd86RR763dGuYP7nGIq763Q"
					],
					"fair": [
						"9jTKbntKp76YYfrzYJFryw",
						"adtkd86RR763dGuYP7nGIq763Q"
					],
					"poor": [
						"9jTKbntKp76YYfrzYJFryw"
					]
				}
			},
			"refinance": {
				"credit": {
					"excellent": [
						"Gab1Plyc33xiqZmq01763XKQ",
						"ulVumiUwvJ4SBLSxXa892jMQ"
					],
					"good": [
						"Gab1Plyc33xiqZmq01763XKQ",
						"ulVumiUwvJ4SBLSxXa892jMQ"
					],
					"average": [
						"Gab1Plyc33xiqZmq01763XKQ",
						"ulVumiUwvJ4SBLSxXa892jMQ"
					],
					"fair": [
						"9gc7eR7TbXlsSI8tjnjtLg",
						"ulVumiUwvJ4SBLSxXa892jMQ"
					],
					"poor": [
						"9gc7eR7TbXlsSI8tjnjtLg"
					]
				}
			}
		}
	},
	"expert_sender": {
		"api_key": "tsCvf1oH6ElFz6uZaEpF",
		"api_url": "https:\/\/api2.esv2.com",
		"lists": {
			"purchase": {
				"excellent": [
					7,
					9
				],
				"good": [
					7,
					9
				],
				"average": [
					7,
					9
				],
				"fair": [
					3,
					7
				],
				"poor": [
					3
				]
			},
			"refinance": {
				"excellent": [
					6,
					8
				],
				"good": [
					6,
					8
				],
				"average": [
					6,
					8
				],
				"fair": [
					5,
					8
				],
				"poor": [
					5
				]
			},
			"lates": [
				19
			]
		}
	},
	"exact_target": {
		"client_id": "4x8badnqnfut4c826z8xj3jp",
		"client_secret": "dp4HPYvPuz5bKGjcX4m755ht",
		"lists": {
			"good_credit_refinance": {
				"id": 69958625,
				"criteria": {
					"credit_rating": [
						"average",
						"good",
						"excellent"
					],
					"loan_purpose": [
						"refinance",
						"harp refinance"
					]
				},
				"data_extension_external_keys": [
					"52E30855-5D9B-4AA0-8748-269CC7352065"
				]
			},
			"bad_credit_refinance": {
				"id": 70057302,
				"criteria": {
					"credit_rating": [
						"fair",
						"poor"
					],
					"loan_purpose": [
						"refinance",
						"harp refinance"
					]
				},
				"data_extension_external_keys": [
					"8C3D3C7D-A7A6-421C-8448-5E03D663B6CC"
				]
			},
			"good_credit_purchase": {
				"id": 70057384,
				"criteria": {
					"credit_rating": [
						"average",
						"good",
						"excellent"
					],
					"loan_purpose": [
						"purchase"
					]
				},
				"data_extension_external_keys": [
					"C726AFC2-E1C4-443D-ADE7-DDF43F6FF58B"
				]
			},
			"bad_credit_purchase": {
				"id": 70057487,
				"criteria": {
					"credit_rating": [
						"fair",
						"poor"
					],
					"loan_purpose": [
						"purchase"
					]
				},
				"data_extension_external_keys": [
					"F1190F9D-DD0F-4130-AE24-789F793896C8"
				]
			},
			"daily_rate_updates_refinance": {
				"id": 70058179,
				"criteria": {
					"credit_rating": [
						"fair",
						"average",
						"good",
						"excellent"
					],
					"loan_purpose": [
						"refinance",
						"harp refinance"
					]
				},
				"data_extension_external_keys": [
					"A6C6F458-6C53-49D0-94B5-60B23B41E6C5"
				]
			},
			"daily_rate_updates_purchase": {
				"id": 70058203,
				"criteria": {
					"credit_rating": [
						"fair",
						"average",
						"good",
						"excellent"
					],
					"loan_purpose": [
						"purchase"
					]
				},
				"data_extension_external_keys": [
					"765936E9-07A1-4D8A-902B-38445E255BA8"
				]
			},
			"late_payments": {
				"id": 70057984,
				"criteria": {
					"opt_mortgage_late_months": [
						">=1"
					]
				},
				"data_extension_external_keys": [
					"3445A3E6-2310-4631-B92A-56B597E3ED92"
				]
			},
			"poor_credit": {
				"id": 70478216,
				"criteria": {
					"credit_rating": [
						"poor"
					]
				},
				"data_extension_external_keys": [
					"E66FD29B-63BF-4920-812A-B332C9F1E2BE"
				]
			},
			"military": {
				"id": 70478682,
				"criteria": {
					"opt_served_military": [
						1
					]
				},
				"data_extension_external_keys": [
					"505B4D31-FFBA-4551-9567-267A9849F8CD"
				]
			},
			"bi_weekly_payments": {
				"id": 70478115,
				"criteria": {
					"bi_weekly": [
						1
					]
				},
				"data_extension_external_keys": [
					"34F0E32C-FAB4-47E0-927D-E7DD33F56FA6"
				]
			},
			"credit_repair": {
				"id": 70478133,
				"criteria": {
					"opt_credit_repair": [
						1
					]
				},
				"data_extension_external_keys": [
					"959F04A2-3D6C-4139-BDAA-8FEFA10BB654"
				]
			},
			"realtor": {
				"id": 70478137,
				"criteria": {
					"agent_found": [
						1
					]
				},
				"data_extension_external_keys": [
					"FFF86DD2-33B3-4B43-9EED-6E5E4C3548CE"
				]
			},
			"solar": {
				"id": 70478118,
				"criteria": {
					"solar": [
						1
					]
				},
				"data_extension_external_keys": [
					"D233E6D3-A0E3-4CA8-80B6-7C24418BB3A8"
				]
			},
			"paintersconnect": {
				"id": 70147068,
				"criteria": {
					"project_type": [
						"~paint"
					]
				},
				"data_extension_external_keys": [
					"43D0D59F-84A6-4C21-815A-E18461FB46B0"
				]
			},
			"connectroofing": {
				"id": 70147081,
				"criteria": {
					"project_type": [
						"~roofing"
					]
				},
				"data_extension_external_keys": [
					"8D368A8E-CEA4-4C53-A7DD-C585571BCFBA"
				]
			},
			"connectwindows": {
				"id": 70147055,
				"criteria": {
					"project_type": [
						"~window"
					]
				}
			},
			"siding": {
				"id": 70919280,
				"criteria": {
					"project_type": [
						"~siding"
					]
				},
				"data_extension_external_keys": [
					"0CA201CC-5EB8-45BF-9F0F-6E6A47EF7761"
				]
			},
			"decks": {
				"id": 70919176,
				"criteria": {
					"project_type": [
						"~decks"
					]
				},
				"data_extension_external_keys": [
					"31A91C04-78B9-4981-B4AF-517F18C62A8A"
				]
			},
			"handyman": {
				"id": 70919165,
				"criteria": {
					"project_type": [
						"~handyman"
					]
				},
				"data_extension_external_keys": [
					"87EFB19E-EDB6-4FA5-969A-F479ECCE4109"
				]
			},
			"kitchen_remodeling": {
				"id": 70919141,
				"criteria": {
					"project_type": [
						"~kitchen"
					]
				},
				"data_extension_external_keys": [
					"5CDAC043-4F74-4F30-AA85-5A41D5A1CC50"
				]
			},
			"bathroom_remodeling": {
				"id": 70919109,
				"criteria": {
					"project_type": [
						"~bathroom"
					]
				},
				"data_extension_external_keys": [
					"0FDE3029-F29C-4778-96F6-D846A94BE8F7"
				]
			},
			"home_security": {
				"id": 70479763,
				"criteria": {
					"home_security": [
						1
					]
				},
				"data_extension_external_keys": [
					"C5BAF531-59F5-4507-812D-E52F10FC20EA"
				]
			},
			"app_rates": {
				"id": 71140421,
				"criteria": {
					"app_rates": [
						1
					]
				},
				"data_extension_external_keys": [
					"4D58307B-11BD-4222-B6F6-6493F8A9DBD9"
				]
			},
			"app_dpa": {
				"id": 71140421,
				"criteria": {
					"app_dpa": [
						1
					]
				},
				"data_extension_external_keys": [
					"3F457AE3-4ED6-48BD-A40E-4A8BE5A8C394"
				]
			}
		}
	},
	"brite_verify": {
		"enabled": true,
		"api_url": "https:\/\/bpi.briteverify.com\/emails.json?address=[email]&apikey=f0144be1-9ae9-4771-932e-08e5cc999007"
	},
	"relevance_reactor": {
		"enabled": true,
		"api_url": "https:\/\/api.rrddm.com\/DDM\/Import.cfc?method=submitRecord&ClientID=174",
		"post_type": {
			"subscriber": {
				"token": "A2J4X8W1D9",
				"token_password": "Q5J3Y2",
				"log_success": false
			},
			"dnc": {
				"token": "V1L4B0B5E2",
				"token_password": "U8T9A1",
				"log_success": true
			},
			"sms_error": {
				"token": "V1L4B0B5E2",
				"token_password": "U8T9A1",
				"log_success": true
			}
		},
		"data_source_id": {
			"subscriber_prod": 16936,
			"subscriber_test": 19687,
			"sms_response": 19853,
			"sms_error": 19854,
			"cebu": 19851,
			"anomaly_squared": 19852
		},
		"test_ips": [
			"127.0.0.1",
			"65.156.156.178",
			"174.79.57.66"
		]
	},
	"international_redirect": {
		"enabled": false,
		"url": "http:\/\/affiliate.gwmtracker.com\/rd\/r.php?sid=2162&pub=202052&c1=&c2=&c3="
	},
	"fontawesome": false,
	"closeout": {
		"show": false,
		"type": "quinstreet",
		"modal": {
			"show_in_frame": true,
			"title": "Wait!",
			"footer": "no thanks"
		},
		"oui_options": {
			"delay": 3000,
			"timer": 1000,
			"aggressive": true
		},
		"wait": {
			"why": "because"
		},
		"vantagemedia": {
			"display_id": 13444,
			"publisher_id": 36365,
			"adsource": "organic",
			"engagement_option": 5,
			"max_results": 5,
			"default_loan_type": 302,
			"campaign": 25036
		},
		"quinstreet": {
			"client_id": 602864
		},
		"homeimprovement": {
			"img_url": null,
			"message_type": "home improvement"
		},
		"rotator": {
			"active": true,
			"types": {
				"vantagemedia": {
					"first_active_weekday_number": 6,
					"last_active_weekday_number": 7
				}
			}
		}
	},
	"facebook": {
		"show_headers": false,
		"show_pixel": true,
		"retarget_id": 952789901420050,
		"conversion_id": 6024632247626,
		"og_type": "website",
		"og_description": "Get matched with lenders who can help you qualify for the best mortgage programs available. It is free and fast!",
		"submit_track_pixel": false
	},
	"leadid": {
		"should_include_script": true,
		"url": "d1tprjo2w7krrh.cloudfront.net\/campaign\/f21bcfe7-421d-0b59-65fe-3d36e8d9c8d6.js?f=submit"
	},
	"apple_app_meta": {
		"show": false
	},
	"froala": {
		"key": "aB-7kA-21nD-17D-13rbjH3j1c1p=="
	},
	"template_options": {
		"thankyou_rotator_options": {
			"default": "thankyou_2",
			"groups": {
				"dow_weekends": {
					"page": "thankyou_free360",
					"priority": 10,
					"criteria": {
						"day_of_week": [
							6,
							7
						]
					}
				},
				"loan_purchase": {
					"page": "thankyou_2_delay",
					"priority": 11,
					"criteria": {
						"loan_purpose": [
							"Purchase"
						],
						"day_of_week": [
							1,
							2,
							3,
							4,
							5
						],
						"time": [
							">=1400"
						]
					}
				}
			}
		}
	},
	"showbanners": {
		"ratespecial": {
			"show": false,
			"sid2": "MORT-CAKE"
		}
	},
	"trusted_form": {
		"show": true,
		"username": "API",
		"password": "fb4162339bcf9961892562e8f86eb790"
	},
	"twilio": {
		"accountSid": "AC4468139d785b9cd95de351b903687acc",
		"authToken": "31e94740968bff8dfa79737790ac8c24",
		"smsTypes": {
			"mortgage_refi_age": {
				"shortCode": 74029,
				"url": "https:\/\/next.guide",
				"url_parameters": [
					"state",
					"loan_purpose",
					"credit_rating",
					"property_type"
				],
				"body": "We got your info and are matching you with a lender. Next step, go to %url%.\n\nMsg & Data rates may apply. Rply STOP to stop & HELP for help",
				"criteria": {
					"credit_rating": [
						"average",
						"good",
						"excellent"
					],
					"loan_purpose": [
						"Refinance",
						"HARP Refinance"
					]
				}
			},
			"mortgage": {
				"shortCode": 74029,
				"url": "https:\/\/next.guide\/a",
				"url_parameters": [
					"state",
					"loan_purpose",
					"credit_rating",
					"property_type"
				],
				"body": "We got your info and are matching you with a lender. Next step, go to %url%.\n\nMsg & Data rates may apply. Rply STOP to stop & HELP for help",
				"criteria": {
					"loan_purpose": [
						"Refinance",
						"HARP Refinance",
						"Purchase"
					]
				}
			}
		},
		"keywords": {
			"HELP": [
				"help",
				"info",
				"ok"
			],
			"STOP": [
				"stop",
				"end",
				"cancel",
				"unsubscribe",
				"quit",
				"fuck",
				"Scam",
				"BS",
				"Bull",
				"shit",
				"bullcrap",
				"bull crap",
				"crooks",
				"Don't",
				"dont",
				"dnt",
				"do not",
				"block",
				"doubt",
				"fu",
				"shut up",
				"hell no",
				"hell",
				"kill",
				"turning",
				"BBB",
				"suck",
				"loser",
				"no",
				"not",
				"remove",
				"srry",
				"st0p",
				"stip",
				"stip6",
				"sto",
				"sto0",
				"stod",
				"unsubscribe",
				"wrong",
				"wrong number",
				"valid",
				"block",
				"ass",
				"kiss",
				"dick",
				"poop",
				"stoo",
				"syopwhatever",
				"what ever",
				"who is this",
				"too late",
				"loan modification",
				"wrong",
				"call",
				"turned down",
				"fucj",
				"DTOP",
				"ztop",
				"atop",
				"dtop",
				"wtop",
				"qtop",
				"ctop",
				"xtop",
				"f u",
				"whos this",
				"lose",
				"stpo",
				"litigate",
				"sue",
				"take me off",
				"what is",
				"fake",
				"loose",
				"lies",
				"forget",
				"srop",
				"drop",
				"dyop",
				"stpp"
			]
		}
	},
	"state_specific": {
		"states": {
			"AK": {
				"name": "Alaska"
			},
			"AL": {
				"name": "Alabama"
			},
			"AR": {
				"name": "Arkansas"
			},
			"AZ": {
				"name": "Arizona"
			},
			"CA": {
				"name": "California"
			},
			"CO": {
				"name": "Colorado"
			},
			"CT": {
				"name": "Connecticut"
			},
			"DC": {
				"name": "Washington D.C."
			},
			"DE": {
				"name": "Deleware"
			},
			"FL": {
				"name": "Florida"
			},
			"GA": {
				"name": "Georgia"
			},
			"HI": {
				"name": "Hawaii"
			},
			"IA": {
				"name": "Iowa"
			},
			"ID": {
				"name": "Idaho"
			},
			"IL": {
				"name": "Illinois"
			},
			"IN": {
				"name": "Indiana"
			},
			"KS": {
				"name": "Kansas"
			},
			"KY": {
				"name": "Kentucky"
			},
			"LA": {
				"name": "Louisiana"
			},
			"MA": {
				"name": "Massachusetts"
			},
			"MD": {
				"name": "Maryland"
			},
			"ME": {
				"name": "Maine"
			},
			"MI": {
				"name": "Michigan"
			},
			"MN": {
				"name": "Minnesota"
			},
			"MO": {
				"name": "Missouri"
			},
			"MS": {
				"name": "Mississippi"
			},
			"MT": {
				"name": "Montana"
			},
			"NC": {
				"name": "North Carolina"
			},
			"ND": {
				"name": "North Dakota"
			},
			"NE": {
				"name": "New England"
			},
			"NH": {
				"name": "New Hampshire"
			},
			"NJ": {
				"name": "New Jersey"
			},
			"NM": {
				"name": "New Mexico"
			},
			"NV": {
				"name": "Nevada"
			},
			"NY": {
				"name": "New York"
			},
			"OH": {
				"name": "Ohio"
			},
			"OK": {
				"name": "Oklahoma"
			},
			"OR": {
				"name": "Oregon"
			},
			"PA": {
				"name": "Pennsylvania"
			},
			"RI": {
				"name": "Rhode Island"
			},
			"SC": {
				"name": "South Carolina"
			},
			"SD": {
				"name": "South Dakota"
			},
			"TN": {
				"name": "Tennessee"
			},
			"TX": {
				"name": "Texas"
			},
			"UT": {
				"name": "Utah"
			},
			"VA": {
				"name": "Virginia"
			},
			"VT": {
				"name": "Vermont"
			},
			"WA": {
				"name": "Washington"
			},
			"WI": {
				"name": "Wisconsin"
			},
			"WV": {
				"name": "West Virginia"
			},
			"WY": {
				"name": "Wyoming"
			}
		}
	}
}, site_config || {});
