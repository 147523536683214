scQuestions.auto_single = {
	1:{
		loan_purpose: {
			separator: '<h1><i class="fa fa-car"> Loan Information </h1>',
			title: 'Type of Loan',
			type: 'select',
			default: '',
			helper: false,
			options: {
				'': '(Select Loan Purpose)',
				'Refinance': 'Refinance',
				'New Car Purchase': 'New Car Purchase',
				'Used Car Purchase': 'Used Car Purchase',
			},
			onChange: scOptions.onChangeAutoLoanPurpose
		},
		vehicle_make: {
			title: 'Vehicle Make'
		},
		vehicle_model: {
			title: 'Vehicle Model'
		},
		vehicle_year: {
			title: 'Vehicle Year'
		},
		rate: {
			title: 'Select your current interest rate',
			type: 'slider',
			default: 5.00,
			condition: {
				loan_purpose: 'Refinance'
			},
			css: {
				margin: '1em auto'
			},
			options: scOptions.autoRates
		},
		loan_balance: {
			title: 'What is your current loan balance?',
			type: 'text',
			condition: {
				loan_purpose: 'Refinance'
			},
			class: 'is-currency',
			attr: {
				placeholder: 'Current Balance'
			}
		},
		payment: {
			title: 'What is your current car payment?',
			type: 'text',
			condition: {
				loan_purpose: 'Refinance'
			},
			attr: {
				placeholder: 'Current Payment'
			}
		},
			credit_rating:{
			type: 'slider'
		},
		first_name:{
			separator: '<h1><i class="fa fa-user"> Personal Information</h1>',
			title:'First Name:'
		},
		last_name:{
			title:'Last Name:'
		},
		address:{
			title:'Address:'
		},
		city:{
			title:'City:'
		},
		state:{
			title:'State:'
		},
		zipcode:{
			title:'Zip Code:'
		},
		email:{
			title:'Email:',
			validate: scValidate.email
		},
		phone_primary:{
			title:'Phone:'
		},
		phone_email_cert: {}
	}
};
