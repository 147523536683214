dtx = {
	apiUrl: 'https://xdc.suitedconnector.com/api/dtx/',

	recordDtxResponse: function(dtxResponse) {
		var encodedResponse = JSON.stringify(dtxResponse);
		var inputSelector = 'input[data-name="dtx_response"]';
		if ($(inputSelector).length === 0) {
			var encodedName = base64_encode('dtx_response');
			scForm.$primaryForm.append('<input type="hidden" data-name="dtx_response" name="' + encodedName + '">');
		}
		$(inputSelector).val(encodedResponse);
	},

	buildVehicleDealerField: function(c) {
		var $form = scForm.$primaryForm, 
			$holder = $(this);

		$holder.change(function(e) {
			var $inputs = $(this).find('.dealer-list input');
			var $t = $(e.target);

			if ($t.is('[value=""]')) {
				//Check / Uncheck all values if user is clicking Select All
				$inputs.prop('checked', !!$t.prop('checked'));
			} else {
				$(this).closest('.input-holder').find('[value=""]').prop('checked', $inputs.length === $inputs.filter(':checked').length);
			}
		});

		$holder
			.addClass('is-loading')
			.append($('<div/>').addClass('sc-loading').html('<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i> Finding dealerships near you...'))
			.append($('<div/>').addClass('sc-find-dealers').html('<i class="fa fa-search"></i> Complete the form to find dealers in your area!'));

		$form.change(function() {
			var data = {
				vehicle_make:  scForm.getFieldValue('vehicle_make'),
				vehicle_model: scForm.getFieldValue('vehicle_model'),
				vehicle_year:  scForm.getFieldValue('vehicle_year'),
				zipcode:       scForm.getFieldValue('zip_code_initial'),
				loan_purpose:  scForm.getFieldValue('loan_purpose')
			};

			if (data.loan_purpose.match(/New Car Purchase/i) && data.vehicle_make && data.vehicle_model && data.vehicle_year && data.zipcode) {
				var resultId = data.vehicle_make + '-' + data.vehicle_model + '-' + data.vehicle_year + '-' + data.zipcode;

				// Don't get results if they've already been retrieved for this data
				if (scOptions.vehicleDealerResultId === resultId) {
					return;
				}

				scOptions.vehicleDealerResultId = resultId;
				var $options = $form.find('.name-vehicle_dealer .sc-options');
				var $dealerList = $options.find('.dealer-list');
				if (!$dealerList.length) {
					$dealerList = $('<div/>').addClass('dealer-list');
					$options.append($dealerList);
				}

				$options.find('[value=""]').prop('checked', false);
				$holder.removeClass('no-dealers').addClass('is-loading');
				scForm.resize();
				data.dtx_test_mode = getQueryParam('test') ? 1 : 0;

				$.get(dtx.apiUrl + 'dealers/new', data, function(response) {
					$dealerList.html('');
					$holder.removeClass('is-loading');

					if (response.success) {
						dtx.recordDtxResponse(response.dtx_response);

						if (response.dealers.length) {
							var name = 'vehicle_dealer';
							var name64 = base64_encode(name);

							var dealerCount = 5;

							for (var dealerName in response.dealers) {
								// Limit to dealerCount # of dealers (Max is 5 per DTX rules!)
								if (dealerCount-- <= 0) break;

								var dealer = response.dealers[dealerName];
								var id = 'sc-dealer-' + dealerName;
								$option = $('<div/>').addClass('field-element');
								$option.append($('<input/>').attr({
									id:                    id,
									type:                  'checkbox',
									name:                  name64,
									'data-name':           name,
									'data-stay-on-change': 1,
									value:                 dealer.Reservation_ID
								}))
									.append($('<label/>')
									.attr('for', id)
									.html(dealer.Name + ', ' + dealer.City + ' <div class="distance">(' + parseInt(dealer.Distance) + ' miles)</div>'));
								$dealerList.append($option);
							}

							scForm.resize();
						} else {
							$holder.addClass('no-dealers');
							console.warn('No dealers returned from DTX API');
							console.warn(response);
						}
					} else {
						$holder.addClass('no-dealers');
						console.warn('DTX API did not return success');
						console.warn(response)
					}
				}, 'json');
			} else {
				$holder.removeClass('is-loading').addClass('no-dealers');
			}
		});
	}
}
