scQuestions.harp_quiz = {
	1:  {
		loan_purpose: {
			title: 'Homeowners: <p>The HARP® Refinance program was first announced in 2009 and since then, over 3 million people have refinanced their home with the HARP® refinance program. In order to be eligible for the HARP® program, you must currently have a mortgage.</p>',
			default: 'Refinance',
			options: {
				'Refinance':  'Yes, I have a mortgage',
				'Purchase': 'No, I don\'t have a mortgage but I want one'
			}
		}
	},
	2:	{
		own_rent: {
			condition: {loan_purpose: 'Purchase'}
		}
	},
	3:  {
		zip_code_initial: {
			title: 'What is your current zip code that you live in?'
		}
	},
	4:  {
		opt_served_military: {
			title: '<p>If you or a family member is a Veteran or active-duty military, you may be eligible for VA housing benefits for military families and that means you could be eligible for zero down financing up to $417,000.</p><br />Do you have a Veteran family member that lives with you?',
			default: '1',
			options: {
				1: 'Yes, we are a Veteran household.',
				0: 'No, we are not a Veteran household.'
			}
		}
	},
	5: {
		agent_found: {
			title: 'Are you working with a Realtor?',
			condition: {loan_purpose: 'Purchase'}
		}
	},
	6:	{
		home_found: {
			condition: {loan_purpose: 'Purchase'}
		}
	},
	7:  {
		credit_rating: {
			title: '<p>This answer does not have to be exact, it\'s okay to estimate here.</p><br />What is your current credit score to the best of your knowledge?<p>(Most people have GOOD credit)</p>'
		}
	},
	8:	{
		auto_refinance: {
			title: 'Are you interested in saving money by refinancing your existing auto loan?',
			condition: {loan_purpose: 'Purchase'}
		}
	},
	9:  {
		property_type: {
			title: 'Is your home or the home you are looking for a',
			default: 'Multi-Family',
			options: {
				'Condominium': 'Condo',
				'Single Family Home': 'Single Family',
				'Multi-Family': 'Multi Family',
				'Townhouse': 'Townhouse'
			}
		}
	},
	10:	{
		moving_date: {
			title: 'When are you planning to buy a house (assuming that you can get qualified)?',
			condition: {loan_purpose: 'Purchase'}
		}
	},
	11: {
	    est_purchase_price: {
			title: 'How much would you like to borrow? (An estimate is fine.)',
			condition: {loan_purpose: 'Purchase'}
		}
	},
	12: {
		estimated_down_payment: {
			type: 'slider',
			condition: {loan_purpose: 'Purchase'}
		}
	},
	13: {
		property_zip: {
			title: 'What zip code are you searching in?',
			condition: {loan_purpose: 'Purchase'}
		}
	},
	14:  {
		mortgage_amount: {
			title: '<p>The HARP Refinance program is designed to help regular American homeowners reduce their monthly mortgage payment.</p><br />What is your current loan amount?',
			condition: {loan_purpose: 'Refinance'}
		},
		est_property_value: {
			title: 'What is the estimated value of your home?',
			condition: {loan_purpose: 'Refinance'}
		}
	},
	15:  {
		mortgage_rate: {
			title: 'What is your current interest rate?<p>If you do not know, take your best guess.</p>',
			condition: {loan_purpose: 'Refinance'}
		}
	},
	16:  {
		current_loan_type: {
			default: 'Other',
			title: '<p>Depending on what kind of mortgage you currently have, you may qualify for the HARP refinance program and/or other types of government refinance programs.</p><br />What type of mortgage do you currently have?',
			condition: {loan_purpose: 'Refinance'}
		}
	},
	17:  {
		email: {
			title: 'Almost done!<p>What email address do you want us to send your results and next steps to?</p>'
		}
	},
	18: {
		first_name: {
			title: 'Enter your information and get your results - fast!',
		},
		last_name: {},
		address: {},
		city: {},
		state: {
			default: 'Select State...'
		},
		zipcode: {},
		phone_primary: {},
		phone_email_cert: {}
	}
};
