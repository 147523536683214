scQuestions.harpeligiblefb = {
	1: {
		loan_purpose: {
			title:   '<h1>Will HARP Save You Money?<i>Find Out Fast!</i></h1><h3>Type of Loan</h3>',
			helper:  '',
			options: {
				'Refinance': '<img src="https://storage.googleapis.com/instapage-user-media/705c019b/8362238-0-harp-refi.png"/>',
				'Purchase':  '<img src="https://storage.googleapis.com/instapage-user-media/705c019b/8362233-0-harp-purc.png"/>'
			}
		}

	},
	2: {
		property_type: {
			title:   'Property Type',
			helper:  '',
			default: 'Multi-Family',
			options: {
				'Single Family': '<img src="https://storage.googleapis.com/instapage-user-media/705c019b/8362198-0-harp-single.png"/>',
				'Multi-Family':  '<img src="https://storage.googleapis.com/instapage-user-media/705c019b/8362228-0-harp-multi.png"/>',
				'Townhouse':     '<img src="https://storage.googleapis.com/instapage-user-media/705c019b/8362223-0-harp-town.png"/>',
				'Condominium':   '<img src="https://storage.googleapis.com/instapage-user-media/705c019b/8362218-0-harp-condo.png"/>'
			}
		}
	},

	3: {
		zip_code_initial: {
			title:  'Zip Code',
			helper: ''
		}
	},
	4: {
		credit_rating: {
			type:    'radio',
			title:   'Rate Your Credit <span>(Most people have GOOD credit)</span>',
			helper:  '',
			options: {
				'excellent': '<img src="https://storage.googleapis.com/instapage-user-media/705c019b/8362213-0-harp-700.png"/>',
				'good':      '<img src="https://storage.googleapis.com/instapage-user-media/705c019b/8362208-0-harp-700-640.png"/>',
				'average':   '<img src="https://storage.googleapis.com/instapage-user-media/705c019b/8362203-0-harp-640-600.png"/>',
				'fair':      '<img src="https://storage.googleapis.com/instapage-user-media/705c019b/8362188-0-harp-600-560.png"/>',
				'poor':      '<img src="https://storage.googleapis.com/instapage-user-media/705c019b/8362193-0-harp-560.png"/>'
			}
		}
	},

	5:  {
		mortgage_amount: {
			condition: {loan_purpose: 'Refinance'},
			helper:    ''
		},
		own_rent:        {
			condition: {loan_purpose: 'Purchase'},
			type:      'select',
			title:     'Do you currently own or rent?',
			options:   {
				'':     'Please Select',
				'Own':  'Own',
				'Rent': 'Rent'
			}
		}
	},
	6:  {
		est_property_value: {
			condition: {loan_purpose: 'Refinance'},
			helper:    ''
		},
		est_purchase_price: {
			condition: {loan_purpose: 'Purchase'}
		}
	},
	7:  {
		mortgage_rate:          {
			condition: {loan_purpose: 'Refinance'}
		},
		estimated_down_payment: {
			condition: {loan_purpose: 'Purchase'}
		}
	},
	8:  {
		agent_found:       {
			condition: {loan_purpose: 'Purchase'},
		},
		current_loan_type: {
			condition: {loan_purpose: 'Refinance'},
			type:      'select',
			helper:    ''
		}
	},
	9:  {
		home_found: {
			condition: {loan_purpose: 'Purchase'}
		}
	},
	10: {
		property_zip: {
			condition: {loan_purpose: 'Purchase'}
		}
	},
	11: {
		moving_date: {
			condition: {loan_purpose: 'Purchase'},
			default:   '',
			options:   {
				'':               'Please Select',
				'Right Away':     'Right Away',
				'Within 90 Days': 'Within 90 Days',
				'3-6 Months':     '3-6 Months',
				'6-12 Months':    '6-12 Months',
				'12+ Months':     '12+ Months'
			}
		}
	},
	12: {
		opt_served_military: {
			title:   ' Have you or your spouse served in the military? <span>Veterans may be eligible for special loan programs.</span>',
			default: 0,
			helper:  ''
		}
	},
	13: {
		first_name: {},
		last_name:  {},
		address:    {},
		city:       {},
		state:      {},
		zipcode:    {}
	},
	14: {
		email:         {},
		phone_primary: {},
		phone_email_cert: {}
	}

};
