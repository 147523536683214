scQuestions.taxdefensecenter = {
  1: {
    tax_debt: {
      title: '<h2>Solve your tax debt problems</h2><h4>How much tax debt do you have?</h4>'
    }
  },
  2: {
    state: {
      title: '<h2>Solve your <upper>IRS</upper> tax problems</h2><h4>What state do you live in?</h4>'
    }
  },
  3: {
    first_name: {
      title: '<h2>You qualify for a free evaluation</h2><h5>Final Step:</h5><p>Complete the form and get your free estimate by a Senior Tax Professional.</p>'
    },
    last_name:  {},
    email:    {},
    phone_primary: {},
    phone_email_cert: {}
  }
};
