scQuestions.reversemortgage_B = {
	1: {
		home_value: {},
		loan_balance: {
			type: 'select',
			title: 'What is  your Current mortgage balance?',
			format: false,
			class: '',
			default: '',
			options: function() {
				var options = {
					'': '(Select mortgage balance)',
					10000: '$10,000 or lower'
				};
				for (var i = 20000; i < 450000; i += 10000) {
					options[i] = '$' + (i - 9999).toLocaleString('en-US') + ' - $' + i.toLocaleString('en-US');
				}
				options[450000] = '$450,000 or higher';
				return options;
			}
		},
		zip_code_initial: {
			title:   'What is your Zip Code?',
			helper: false
		}
	},
	2:{
		age: {},
		first_name: {
			title: 'What is your first and last name?'
		},
		last_name:{},
		address:{
			title:'What is your current address?'
		},
		zipcode:{},
		city:{},
		state:{},
	},
	3:{
		phone_primary:{
			title:'Phone number'
		},
		email:{
			title:'Email Address'
		},
		phone_email_cert: {}
	}
};


scQuestions.reversemortgage_B_config = {};
