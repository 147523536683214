scQuestions.aag = {
	1: {
    		zip_code_initial: {
    			title:   'What is your Zip Code?<br><span>Your zip code will help us make sure you live in the United States</span>',
    			helper: false
    		}
    	},
    	2:{
    		address:{
    			title:'What is your current address?'
    		},
    		zipcode:{},
    		city:{},
    		state:{},
    		first_name: {
    			title: 'What is your first and last name?'
    		},
    		last_name:{},
    	},
    	3:{
    		loan_balance: {
    			type: 'select',
    			title: 'What is your Current mortgage balance',
    			format: false,
    			class: '',
    			default: '',
    			options: function() {
    				var options = {
    					'': '(Select mortgage balance)',
    					10000: '$10,000 or lower'
    				};
    				for (var i = 20000; i < 450000; i += 10000) {
    					options[i] = '$' + (i - 9999).toLocaleString('en-US') + ' - $' + i.toLocaleString('en-US');
    				}
    				options[450000] = '$450,000 or higher';
    				return options;
    			}
    		},
    		home_value: {},
    	},
    	4:{
    		age: {},
    	},
    	5:{
    		phone_primary:{
    			title:'Phone number'
    		},
    		email:{
    			title:'Email Address'
    		},
            phone_email_cert: {}
    	}
};
