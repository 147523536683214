scQuestions.rent2ownqualified = {
	1: {
		zip_code_initial: {
			title:  '<span class="enter-zip">Enter your Zip Code</span> to Search for Listings',
			helper: '',
			attr:   {
				placeholder: '',
			}
		}
	},
	2: {
		property_type: {
			helper: '',
		}
	},
	3: {
		credit_rating: {
			helper: '',
		}
	},
	4: {
		auto_refinance: {
			helper: '<p><img src="https://storage.googleapis.com/instapage-user-media/705c019b/11180883-0-check.png">&nbsp;Customers save an average of $112 per month on their car payment and $2,753 over the life of the auto loan</p><p><img src="https://storage.googleapis.com/instapage-user-media/705c019b/11180883-0-check.png">&nbsp;You could skip your next car payment</p><p><img src="https://storage.googleapis.com/instapage-user-media/705c019b/11180883-0-check.png">&nbsp;Get a possible warranty refund</p><p class="text-center" style="margin-top:15px;">Data and free loan quotes are provided by iLendingDirect</p><p class="text-center" style="background: #fff;margin-top: 20px;"><img src="https://storage.googleapis.com/instapage-user-media/705c019b/11181278-0-logo-ilendingdirect.png" style="margin: 15px auto;" /><br/><img src="https://storage.googleapis.com/instapage-user-media/705c019b/11181283-0-logo-partners.png" style="width:100%;" /></p>',
		}
	},
	5: {
		opt_served_military: {
			helper: '',
		}
	},
	6: {
		first_name:    {
			title: 'Last Step'
		},
		last_name:     {},
		address:       {},
		city:          {},
		state:         {},
		zipcode:       {},
		email:         {},
		phone_primary: {},
		phone_email_cert: {}
	}
};

jQuery('.sc-slide:eq(0)').find('.field-element').append('<span class="step1-search button-next">SEARCH</span>');
jQuery('.sc-slide:eq(1)').find('.sc-options').append('<span class="step1-back button-next">Back</span>');
jQuery('.sc-slide:eq(1)').find('.sc-options').append('<span class="step1-next button-next">Next</span>');
jQuery('.sc-slide:eq(2)').find('.sc-options').append('<span class="step1-back button-next">Back</span>');
jQuery('.sc-slide:eq(2)').find('.sc-options').append('<span class="step1-next button-next">Next</span>');
jQuery('.sc-slide:eq(3)').find('.sc-options').append('<span class="step1-back button-next">Back</span>');
jQuery('.sc-slide:eq(3)').find('.sc-options').append('<span class="step1-next button-next">Next</span>');
jQuery('.sc-slide:eq(4)').append('<span class="step1-back button-next">Back</span>');
jQuery('.sc-slide:eq(4)').append('<span class="step1-next button-next">Submit</span>');
jQuery(document).on('click', '.step1-search', function(){
	jQuery('.sc-btn-next')[0].click();
});
jQuery(document).on('click', '.step1-back', function(){
	jQuery('.sc-btn-prev')[0].click();

});

jQuery(document).on('click', '.step1-next', function(){
	jQuery('.sc-btn-next')[0].click();
});
