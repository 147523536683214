scQuestions.personal = {
	1: {
		loan_purpose: {
			type:    'select',
			title:   'How will you use the loan?',
			options: ['Debt Consolidation', 'Home Improvement', 'Wedding', 'Vacation', 'Major Purchase', 'Purchase a Vehicle', 'Medical', 'Other'],
			default: 'Debt Consolidation',
			helper: 'Are you looking to consolidate your debt, pay off medical bills or take your dream vacation?  Based on what you select here, we will have just a few more questions to help you get matched up with a lender. The entire process shouldn’t take more than 2 minutes.'
		}
	},
	2: {
		loan_amount: {}
	},
	3: {
		credit_rating: {}
	},
	4: {
		bk_foreclosure_repo: {}
	},
	5: {
		job_length: {}
	},
	6: {
		gross_income: {}
	},
	7: {
		first_name: {},
		last_name:  {},
		address:    {},
		city:       {},
		state:      {},
		zipcode:    {}
	},
	8: {
		email:         {},
		phone_primary: {},
		phone_email_cert: {}
	}
};


scQuestions.personal_config = {
	disclosure: 'personal',
	privacyUrl: '/pages/display/privacy_ec'
};
