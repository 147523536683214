scQuestions.blownmortgage = {
      1: {
        loan_purpose: {
          options: {
            'Refinance': '<img src="https://storage.googleapis.com/instapage-user-media/705c019b/8264758-0-refinance.png"/>',
            'Purchase':  '<img src="https://storage.googleapis.com/instapage-user-media/705c019b/8264753-0-purchase.png"/>'
          },
          helper: '',
          title: '<h2><p>President Announces FHA Refinancing is Now Cheaper?</p><p>Yes, it\'s true.</p></h2>'
        }
      },
      2: { property_type: {
          options: {
            'Single Family': '<img src="https://storage.googleapis.com/instapage-user-media/705c019b/8308198-0-single-family.png"/>',
            'Multi Family':  '<img src="https://storage.googleapis.com/instapage-user-media/705c019b/8308193-0-multi-family.png"/>',
            'Town House':  '<img src="https://storage.googleapis.com/instapage-user-media/705c019b/8308203-0-town-house.png"/>',
            'Condo':  '<img src="https://storage.googleapis.com/instapage-user-media/705c019b/8308188-0-condo.png"/>'
          },
          helper: ''
        }
      },
      3: { zip_code_initial: {
           helper: ''
      }  },
      4: { credit_rating: {
          options: {
            'Excellent': '<img src="https://storage.googleapis.com/instapage-user-media/705c019b/8308633-0-excellent.png"/>',
            'Good':  '<img src="https://storage.googleapis.com/instapage-user-media/705c019b/8308638-0-good.png"/>',
            'Average':  '<img src="https://storage.googleapis.com/instapage-user-media/705c019b/8308668-0-average.png"/>',
            'Fair':  '<img src="https://storage.googleapis.com/instapage-user-media/705c019b/8308643-0-fair.png"/>',
            'Poor':  '<img src="https://storage.googleapis.com/instapage-user-media/705c019b/8308648-0-poor.png"/>'
          },
          helper: ''
        }
      },
      5: {
        own_rent: {
          type: 'select',
          helper: '',
          options: {
          	'': 'Please select',
          	'Own': 'Own',
          	'Rent': 'Rent'
          }
        }
      },
      6:  {
  		est_property_value: {
  			condition: {loan_purpose: 'Refinance'},
            helper: ''
  		},
  		est_purchase_price: {
  			condition: {loan_purpose: 'Purchase'}
  		}
  	},
  	7:  {
  		mortgage_rate:          {
  			condition: {loan_purpose: 'Refinance'}
  		},
  		estimated_down_payment: {
  			condition: {loan_purpose: 'Purchase'}
  		}
  	},
  	8:  {
  		agent_found:       {
            type:    'select',
  			condition: {loan_purpose: 'Purchase'},
  		},
  		current_loan_type: {
            type:    'select',
  			condition: {loan_purpose: 'Refinance'},
            helper: ''
  		}
  	}, 
  	9:  {
  		home_found: {
  			condition: {loan_purpose: 'Purchase'}
  		}
  	},
  	10: {
  		property_zip: {
  			condition: {loan_purpose: 'Purchase'}
  		}
  	},
  	11: {
  		moving_date: {
  			condition: {loan_purpose: 'Purchase'}
  		}
  	},
  	12: {
  		opt_served_military: {
            type: 'select',
            helper: ''
        }
  	},
  	13: {
  		first_name: {},
  		last_name:  {},
  		address:    {},
  		city:       {},
  		state:      {},
  		zipcode:    {}
  	},
  	14: {
  		email:         {},
  		phone_primary: {},
      phone_email_cert: {}
  	}

};
