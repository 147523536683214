scQuestions.mortgagecommentatorlpmi = {
	1:  {
		loan_purpose: {
			options: {
				'Refinance': '<img src="https://storage.googleapis.com/instapage-user-media/705c019b/8477743-0-image-1-a.png">',
				'Purchase':  '<img src="https://storage.googleapis.com/instapage-user-media/705c019b/8477753-0-image-1-b.png">'
			},
			helper:  '',
			title:   '<h1 class="step-1-title">Do You Qualify For Lender Paid Mortgage Insurance Programs?</h1><br><p class="step-1-content">Start Here</p>',
		}
	},
	2:  {
		property_type: {
			options: {
				'Single Family': '<img src="https://storage.googleapis.com/instapage-user-media/705c019b/8477758-0-image-2-a.png">',
				'Multi-Family':  '<img src="https://storage.googleapis.com/instapage-user-media/705c019b/8477748-0-image-2-b.png">',
				'Townhouse':     '<img src="https://storage.googleapis.com/instapage-user-media/705c019b/8477763-0-image-2-c.png">',
				'Condominium':   '<img src="https://storage.googleapis.com/instapage-user-media/705c019b/8477768-0-image-2-d.png">'
			},
			helper:  ''
		}
	},
	3:  {
		zip_code_initial: {
			helper: ''
		}
	},
	4:  {
		credit_rating: {
			helper:  '',
			options: {
				'excellent': '<img src="https://storage.googleapis.com/instapage-user-media/705c019b/8477778-0-image-3-a.png">',
				'good':      '<img src="https://storage.googleapis.com/instapage-user-media/705c019b/8477773-0-image-3-b.png">',
				'average':   '<img src="https://storage.googleapis.com/instapage-user-media/705c019b/8477783-0-image-3-c.png">',
				'fair':      '<img src="https://storage.googleapis.com/instapage-user-media/705c019b/8477788-0-image-3-d.png">',
				'poor':      '<img src="https://storage.googleapis.com/instapage-user-media/705c019b/8477793-0-image-3-e.png">'
			}
		}
	},
	5:  {
		mortgage_amount: {
			condition: {loan_purpose: 'Refinance'}
		},
		own_rent:        {
			condition: {loan_purpose: 'Purchase'},
			options:   {
				'':     'Please Select',
				'Own':  'Own',
				'Rent': 'Rent'
			},
			type:      'select',
		}
	},
	6:  {
		est_property_value: {
			condition: {loan_purpose: 'Refinance'}
		},
		est_purchase_price: {
			condition: {loan_purpose: 'Purchase'}
		}
	},
	7:  {
		mortgage_rate:          {
			condition: {loan_purpose: 'Refinance'}
		},
		estimated_down_payment: {
			condition: {loan_purpose: 'Purchase'}
		}
	},
	8:  {
		agent_found:       {
			condition: {loan_purpose: 'Purchase'},
		},
		current_loan_type: {
			condition: {loan_purpose: 'Refinance'}
		}
	},
	9:  {
		home_found: {
			condition: {loan_purpose: 'Purchase'}
		}
	},
	10: {
		property_zip: {
			condition: {loan_purpose: 'Purchase'}
		}
	},
	11: {
		moving_date: {
			condition: {loan_purpose: 'Purchase'}
		}
	},
	12: {
		opt_served_military: {}
	},
	13: {
		first_name: {},
		last_name:  {},
		address:    {},
		city:       {},
		state:      {},
		zipcode:    {}
	},
	14: {
		email:         {},
		phone_primary: {},
		phone_email_cert: {}
	}
};
