scQuestions.harpleaderverify = {
		1: {
			loan_purpose:             {
              type:    'visible',
              default: 'Refinance',
              helper: ''
			},
			property_type:            {
				type: 'select',
				helper: ''
			},
			mortgage_amount:          {
             type: 'select',
			 helper: ''
			},
			est_property_value:       {
              type: 'select',
				helper: ''
			},
			mortgage_rate:            {
				helper: ''
			},
			opt_mortgage_late_months: {
                type: 'select',
				helper: ''
			},
			current_loan_type:        {
                type: 'select',
				helper: ''
			},
			bk_foreclosure_repo:      {
                type: 'select',
				helper: ''
			},
			credit_rating:            {
				type: 'select',
				helper: ''
			},
			opt_served_military:      {
                type: 'select',
				helper: ''
			},

			first_name:    {
				title: '<i class="fa fa-user"></i> Personal Information'
			},
			last_name:     {},
			address:       {},
			city:          {},
			state:         {},
			zipcode:       {},
			email:         {},
			phone_primary: {}
		}
};
