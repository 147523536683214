scQuestions.personal_single = {
	1: {
		loan_purpose:        {
			separator: '<h1><i class="fa fa-usd"> Loan Information </h1>',
			title:     'How will you use the loan',
			helper:    false,
			type:      'select',
			default:   'Debt Consolidation',
			options:   ['Debt Consolidation', 'Home Improvement', 'Wedding', 'Vacation', 'Major Purchase', 'Medical', 'Purchase a Vehicle', 'Other']
		},
		loan_amount:         {
			helper: false
		},
		credit_rating:       {
			type: 'slider'
		},
		bk_foreclosure_repo: {},
		job_length:          {},
		gross_income:        {},
		first_name:          {
			separator: '<h1><i class="fa fa-user"> Personal Information</h1>',
			title:     'First Name:'
		},
		last_name:           {
			title: 'Last Name:'
		},
		address:             {
			title: 'Address:'
		},
		city:                {
			title: 'City:'
		},
		state:               {
			title: 'State:'
		},
		zip_code:            {
			title: 'Zip Code:'
		},
		email:               {
			title: 'Email:'
		},
		phone_primary:       {
			title: 'Phone:'
		},
		phone_email_cert: {}
	}
};


scQuestions.personal_single_config = {
	disclosure:       'personal',
	privacyUrl:       '/pages/display/privacy_ec',
	controlsAtTop:    true,
	progressBar:      false,
	submitText:       'Looks Good!',
	helperAfterTitle: true
};
