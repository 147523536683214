var scConfig = {
	edmundsApiKey: 'qx6278zyq6y452fec83ggng2',
};

var scDisclosures = {
	personal: 'By clicking the button above, you acknowledge, consent and agree to the following: our <a class="privacy-policy">Privacy Policy</a> and consent to receive notices and other communications electronically; We take your privacy seriously. By clicking the button, you agree to be matched with partners from our network, and consent (not required as a condition to purchase a good/service) for us and/or them to contact you (including through automated means; e.g. autodialing, text and pre-recorded messaging) via telephone, mobile device (including SMS and MMS), and/or email, even if you are on a corporate, state or national Do Not Call Registry.',
};

var creditRatings = {
	poorNotice: 'Check here for a credit repair consultation from our partners. I understand that by checking this box, I expressly consent and agree to receive future texts, SMS, e-mails or calls that deliver autodialed and/or pre-recorded telemarketing messages about credit repair or credit score offers from our credit repair Premier Partners. I understand and agree that my consent is not a condition of purchase.',
}

//Questions are populated in /js/questions/*.js files
var scQuestions = $.extend({}, scQuestions || {});

var scValidate = {
	zip: [
		{
			regex: /^[\d]{5}$/,
			msg: "Zip Code must be 5 numeric digits"
		}
	],
	phone: [
		{
			callback: function(p) {
				p = p.replace(/[^\d]/g, '');
				return p.length >= 10;
			},
			msg: 'Phone number must be 10 digits'
		}
	],
	moving_date: [
		{
			regex: /^$/,
			msg: "Moving date is required"
		}
	],
	// Per Justin's request: LET ALL EMAILS THROUGH! No validation on email (only formatting).
	email: [
		{
			regex: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
			msg: "Please enter a valid email address"
		}
	]
};

var scFormat = {
	zip: function(z) {
		return z.replace(/[^\d]/g, '').substr(0, 5);
	},
	phone: function(p) {
		var n = p.replace(/[^\d]/g, '');
		if (!n) return '';
		p = '(' + n.charAt(0) + n.charAt(1) + n.charAt(2);
		if (n.length > 3) {
			p += ') ' + n.charAt(3) + n.charAt(4) + n.charAt(5);
			if (n.length > 6) {
				p += '-' + n.charAt(6) + n.charAt(7) + n.charAt(8) + n.charAt(9);
			}
		}
		return p;
	},
	nospace: function(s) {
		return s.replace(/\s+/, '');
	},
	currency: function(c) {
		return parseFloat(c.replace(/[^\d\.]/g, '')).currency();
	},
	integer: function(c) {
		return c.replace(/[^\d]/g, '');
	},
	float: function(c) {
		return c.replace(/[^\d\.]/g, '').replace(/(\..*)\./g, '$1');
	},
	date: function(date) {
		var number = date.replace(/[^\d]/g, '');
		if (!number) return '';
		if (number.length > 4) {
			date = number.slice(0, 2) + '/' + number.slice(2, 4) + '/' + number.slice(4, 8);
		} else if (number.length > 2 && number.length < 4) {
			date = number.slice(0, 2) + '/' + number.slice(2, 4);
		}
		return date;
	},
};

var scOptions = {
	vehicleDataType: {},
	vehicleData: {},
	autoRates: function() {
		var rates = {};
		for (var i = 0.05; i <= 25; i += 0.05) {
			num = i.toFixed(2);
			rates[num] = num + '%';
		}
		return rates;
	},
	setVehicleYears: function($form) {
		var purpose = $form.getVal('loan_purpose'),
			thisYear = new Date().getFullYear(),
			years = {}

		var startYear = purpose === 'New Car Purchase' ? thisYear : 2000;

		for (var i = startYear; i <= thisYear + 1; i++) {
			years[i] = i;
		}

		var $year = $form.find('[data-name=vehicle_year]');

		$year.val('').change().find('option').not('[value=""]').remove();

		$.each(years, function(year) {
			$year.append($('<option/>').attr('value', year).html(year));
		});
	},
	vehicleYears: function($form) {
		$form.on('form-loaded', function() {
			scOptions.setVehicleYears($form);
		});
		return {'': '(Select Year)'};
	},
	vehicleMakes: function() {
		var makes = {'': '(Select Make)'};
		for (var make in scAutoMakeAndModelData) {
			makes[make] = make;
		}
		return makes;
	},
	onChangeVehicleMake: function() {
		var $make = $(this),
			make = $make.val(),
			models = scAutoMakeAndModelData[make],
			$model = $make.closest('.sc-form').find('[data-name=vehicle_model]');

		if ($model.length) {
			$model.find('option').not('[value=""]').remove();
			if (models) {
				for (var i in models) {
					var model = models[i];
					$model.append($('<option/>').attr('value', model).html(model));
				}
			}
		}
	},
	onChangeCreditRating: function() {
		var $make = $(this),
			fieldsWrapper = $make.closest('.sc-options'),
			$createInput = $('<input type="checkbox" data-stay-on-change="1" />').attr({'name': 'opt_credit_repair','data-name': 'opt_credit_repair'}).prop('checked', true),
			$createPoorNotice = $('<p />').html($createInput).append(' '+creditRatings.poorNotice),
			$createNoticeWrapper = $('<div />').hide().css('clear', 'both').addClass('poor-notice-wrapper'),
			$createNoticeHtml = $createNoticeWrapper.html($createPoorNotice);

		switch ($make.val().toLowerCase()) {
			case 'excellent':
			case 'good':
			case 'average':
				scForm.next.call(this, event);
				break;
			default:
				if (!fieldsWrapper.hasClass('poor-notice-active')) {
					fieldsWrapper.find('.poor-notice-wrapper').remove(); 	
					fieldsWrapper.append($createNoticeHtml).find('.poor-notice-wrapper').show(); 
				}
				
				fieldsWrapper.addClass('poor-notice-active');
				break;
		}					
		
	},
	onChangeVehicleModel: function($form) {
		scOptions.setVehicleYears($(this).closest('.sc-form'))
	},
	states: function() {
		return {
			'': '(Select State)',
			AK: "Alaska",
			AL: "Alabama",
			AR: "Arkansas",
			AZ: "Arizona",
			CA: "California",
			CO: "Colorado",
			CT: "Connecticut",
			DC: "Washington D.C.",
			DE: "Deleware",
			FL: "Florida",
			GA: "Georgia",
			HI: "Hawaii",
			IA: "Iowa",
			ID: "Idaho",
			IL: "Illinois",
			IN: "Indiana",
			KS: "Kansas",
			KY: "Kentucky",
			LA: "Louisiana",
			MA: "Massachusetts",
			MD: "Maryland",
			ME: "Maine",
			MI: "Michigan",
			MN: "Minnesota",
			MO: "Missouri",
			MS: "Mississippi",
			MT: "Montana",
			NC: "North Carolina",
			ND: "North Dakota",
			NE: "New England",
			NH: "New Hampshire",
			NJ: "New Jersey",
			NM: "New Mexico",
			NV: "Nevada",
			NY: "New York",
			OH: "Ohio",
			OK: "Oklahoma",
			OR: "Oregon",
			PA: "Pennsylvania",
			RI: "Rhode Island",
			SC: "South Carolina",
			SD: "South Dakota",
			TN: "Tennessee",
			TX: "Texas",
			UT: "Utah",
			VA: "Virginia",
			VT: "Vermont",
			WA: "Washington",
			WI: "Wisconsin",
			WV: "West Virginia",
			WY: "Wyoming"
		};
	},
	priceRanges: function(mapList, d) {
		var options = d || {};

		for (var m in mapList) {

			var map = mapList[m];
			for (i = map.low; i <= map.high; i += map.inc) {
				var l = i, h = (i + map.inc);
				options[(h + l) / 2] = '$' + l.currency(map.round || 0) + ' - $' + (h - (map.i || 0)).currency(map.round || 0);
			}
		}

		return options;
	},
	interestCalc: 				function(mapList, ratesList) {

		var options = ratesList || {};

		for (var m in mapList) {
			 var interestRate = mapList[m];

			 for (i = interestRate.low; i <= interestRate.high; i += interestRate.inc) {
			 	var val = i;
				options[val.toFixed(2)] = val;
			 }
		}

		return options;
	},
	purchaseAmounts: function() {
		map = [
			{low: 75000, high: 195000, inc: 5000},
			{low: 200000, high: 390000, inc: 10000},
			{low: 400000, high: 980000, inc: 20000}
		];

		options = scOptions.priceRanges(map, null, '000');
		options[1000000] = 'Over $1,000,000';

		return options;
	},
	mortgageAmounts: function() {
		map = [
			{low: 80000, high: 195000, inc: 5000},
			{low: 200000, high: 390000, inc: 10000},
			{low: 400000, high: 980000, inc: 20000}
		];

		options = scOptions.priceRanges(map, null, '000');

		options[1000000] = "$1,000,000";
		options[1500000] = "$1,500,000";
		options[2000000] = "$2,000,000+";

		return options;
	},
	interestRates: function() {
		map = [
			{low: 0.25, high: 10, inc: 0.25}
		];

		options = scOptions.interestCalc(map);


		return options;
	},
	monthlyIncome: function() {
		map = [
			{low: 0, high: 9000, inc: 1000, i: 1}
		];

		options = scOptions.priceRanges(map);

		options[10000] = '$10,000 or more';

		return options;
	}
};

var scFields = {
	default: {
		validate: [{msg: "Please fill out this field"}]
	},
	address: {
		type: 'text',
		attr: {
			placeholder: 'Current Home Address',
			autocomplete: 'street-address'
		}
	},
	age: {
		type: 'select',
		title: 'What is the age of the homeowner on the title?',
		default: '',
		options: function() {
			var options = {
				'': '(Select homeowner age)',
				59: 'Under 60'
			}
			for (var i = 60; i < 90; i++) options[i] = i;
			options[90] = 'Over 90';
			return options;
		}
	},
	agent_found: {
		type: 'radio',
		title: 'Are you working with a real estate agent?',
		default: 1,
		options: ['1:Yes', '0:No']
	},
	auto_refinance: {
		type: 'radio',
		title: 'Are you interested in saving money by refinancing your existing auto loan?',
		default: 1,
		options: ['1:Yes', '0:No']
	},
	birthday: {
		type: 'text',
		title: 'Please enter your Date of Birth',
		attr: {
			placeholder: 'MM/DD/YYYY'
		},
		format: scFormat.date
	},
	bk_foreclosure_repo: {
		type: 'radio',
		title: 'Have you ever had a BK, Foreclosure, or Repossession?',
		default: 'No',
		options: ['Yes', 'No']
	},
	city: {
		type: 'text',
		attr: {
			placeholder: 'City',
			autocomplete: 'address-level2',
		},
	},
	credit_rating: {
		type: 'radio',
		title: 'Rate Your Credit',
		helper: 'Providing your best estimate of your credit rating is important to help us match you with a lender specializing in helping people with similar credit ratings. If you have excellent credit, we will do our best to match you with a lender who specializes in helping people with excellent credit. If you have fair or poor credit, we will do our best to match you with a lender who specializes in helping people with less than perfect credit.',
		default: 'good',
		onChange: scOptions.onChangeCreditRating,
		attr: {
			'data-stay-on-change': 'true'
		},
		options: {
			'excellent': 'Excellent (700+)',
			'good': 'Good (700-640)',
			'average': 'Average (640-600)',
			'fair': 'Fair (600-560)',
			'poor': 'Poor (Below 560)'
		}
	},
	opt_credit_repair: {
		type: 'checkbox',
		default: 1
	},
	current_loan_type: {
		type: 'radio',
		title: 'Current Loan Type',
		helper: 'Depending on your current loan type, there may be special programs available to you that are based on what your current loan is. For example, if you currently have a VA loan, the VA streamline program may be available – which is only available to people who currently have a VA loan.',
		default: 'VA',
		options: ['FHA', 'VA', 'Other'],
	},
	email: {
		type: 'text',
		format: scFormat.nospace,
		attr: {
			autocomplete: 'email',
			placeholder: 'Email'
		}
	},
	est_property_value: {
		type: 'slider',
		title: 'Estimated Home Value',
		helper: 'While this number doesn’t have to be exact, do your best to estimate the value of your home.',
		default: 255000,
		options: scOptions.mortgageAmounts
	},
	est_purchase_price: {
		type: 'slider',
		title: 'Purchase Price',
		hideEmptyOption: true,
		default: 152500,
		options: scOptions.purchaseAmounts
	},
	estimated_down_payment: {
		type: 'select',
		title: 'Down Payment',
		default: 5,
		options: {
			5: '5%',
			10: '10%',
			15: '15%',
			20: '20%',
			25: '25% or more'
		},
		attr: {
			'data-stay-on-change': 'true'
		},
		format: scFormat.float
	},
	estimated_purchase_price: {
		type: 'text',
		format: scFormat.integer,
		class: 'is-currency'
	},
	first_name: {
		type: 'text',
		attr: {
			placeholder: 'First Name',
			autocomplete: 'given-name'
		}
	},
	gross_income: {
		type: 'select',
		title: 'Estimate your Monthly Gross Income',
		options: scOptions.monthlyIncome,
		default: 3500
	},
	home_found: {
		type: 'radio',
		title: 'Have you found a home?',
		default: 1,
		options: ['1:Yes', '0:No']
	},
	home_value: {
		type: 'select',
		title: 'What is your Estimated home value',
		default: '',
		options: function() {
			var options = {
				'': '(Select estimated home value)',
				'75000': '$75,000 or lower'
			};
			for (var i = 80000; i <= 100000; i += 5000) {
				options[i] = '$' + (i - 4999).toLocaleString('en-US') + ' - $' + i.toLocaleString('en-US');
			}
			for (var i = 125000; i <= 1000000; i += 25000) {
				options[i] = '$' + (i - 24999).toLocaleString('en-US') + ' - $' + i.toLocaleString('en-US');
			}
			options[1000000] = '$1,000,000 or higher';
			return options;
		}
	},
	job_length: {
		type: 'select',
		title: 'How long have you had your current job?',
		default: '2 years',
		options: ['1 year or less', '2 years', '3 years', '4 years', '5 years', '6 years', '7 years', '8 years', '9 years', 'More than 10 years']
	},
	last_name: {
		type: 'text',
		attr: {
			placeholder: 'Last Name',
			autocomplete: 'family-name'
		}
	},
	loan_amount: {
		type: 'text',
		title: 'How much do you need?',
		helper: 'How much money would you like to borrow?',
		format: scFormat.integer,
		class: 'is-currency'
	},
	loan_balance: {
		type: 'text',
		format: scFormat.integer,
		class: 'is-currency'
	},
	loan_purpose: {
		type: 'radio',
		title: 'Choose a loan type',
		helper: 'Are you looking to refinance an existing property or purchase a new one? Based on what you select here, we will have just a few more questions to help you get matched up with a lender. The entire process shouldn’t take more than 2 minutes.',
		default: 'Refinance',
		options: {
			'Refinance': 'VA Loan Refinance',
			'Purchase': 'VA Purchase Loan'
		}
	},
	mortgage_amount: {
		type: 'slider',
		title: 'Current Loan Amount',
		helper: 'This number doesn’t need to be exact – just give us your best to estimate.',
		options: scOptions.mortgageAmounts,
		default: 152500
	},
	mortgage_rate: {
		type: 'text',
		title: 'Current Interest Rate',
		options: scOptions.interestRates,
		default: 4.5,
		attr: {
			'data-stay-on-change': 'true'
		}
	},
	moving_date: {
		type: 'select',
		title: 'When do you plan on moving in?',
		default: 'Right Away',
		options: ['Right Away', 'Within 90 Days', '3-6 Months', '6-12 Months', '12+ Months'],
	},
	opt_mortgage_late_months: {
		type: 'slider',
		title: 'Are you current on your mortgage?',
		default: 0,
		options: ['I\'m not behind', '1 Month Late', '2 Months Late', '3 Months Late', '4 Months Late', '4+ Months Late']
	},
	opt_served_military: {
		type: 'radio',
		title: 'Have you or your spouse served in the military?',
		helper: 'If you (or your spouse) are a Veteran or Active Military member, you may be eligible for special government programs.',
		default: 1,
		options: ['1:Yes', '0:No']
	},
	own_rent: {
		type: 'radio',
		title: 'Do you currently rent or own?',
		options: ['Own', 'Rent'],
	},
	payment: {
		format: scFormat.integer,
		class: 'is-currency'
	},
	phone_email_cert: {
		class: 'phone-email-cert',
		type: 'checkbox',
		default: '1',
		options: {
			1: 'I certify that I am the subscriber to this phone number and e-mail address.'
		},
		attr: {
			'data-unchecked-value': 0
		},
		validate: [
			{
				empty: false,
				msg: "Please confirm your email address and phone number by checking the box"
			}
		]
	},
	phone_primary: {
		type: 'text',
		validate: scValidate.phone,
		format: scFormat.phone,
		attr: {
			type: 'tel',
			autocomplete: 'tel',
			placeholder: 'Primary Phone'
		}
	},
	property_type: {
		type: 'radio',
		title: 'Choose your property type',
		helper: 'Lenders have different programs for different types of properties. Select the property type that you are interested in speaking with a lender about.',
		default: 'Single Family Home',
		options: {
			'Single Family Home': 'Single Family',
			'Multi-Family': 'Multi Family',
			'Townhouse': 'Townhouse',
			'Condominium': 'Condo'
		}
	},
	property_zip: {
		type: 'text',
		title: 'What zip code are you searching in?',
		validate: scValidate.zip,
		format: scFormat.zip,
		attr: {
			autocomplete: 'no',
			placeholder: 'Zip Code',
			pattern: '[0-9]*',
			inputmode: 'numeric'
		}
	},
	rate: {
		format: scFormat.float,
		class: 'is-percent',
		options: {
			5: '5%',
			10: '10%',
			15: '15%',
			20: '20%',
			25: '25% or more'
		}
	},
	state: {
		type: 'select',
		default: '',
		options: scOptions.states,
		attr: {
			autocomplete: 'address-level3',
		},
	},
	tax_debt: {
		type: 'select',
		title: 'How much tax debt do you have?',
		default: '',
		options: [':Please Select', '5000:$0 - $9,999', '15000:$10,000 - $19,999', '35000:$20,000 - $49,999', '50000:$50,000+']
	},
	vehicle_make: {
		type: 'select',
		default: '',
		options: scOptions.vehicleMakes,
		onChange: scOptions.onChangeVehicleMake
	},
	vehicle_model: {
		type: 'select',
		default: '',
		options: {'': '(Select Model)'},
		onChange: scOptions.onChangeVehicleModel
	},
	vehicle_year: {
		type: 'select',
		default: '',
		options: scOptions.vehicleYears
	},
	vehicle_dealer: {
		type: 'checkbox',
		default: '',
		options: {
			'': 'Select All Dealers'
		},
		onBuild: dtx.buildVehicleDealerField
	},
	zip_code: {
		type: 'text',
		validate: scValidate.zip,
		format: scFormat.zip,
		attr: {
			placeholder: 'Zip Code',
			autocomplete: 'postal-code',
			pattern: '[0-9]*',
			inputmode: 'numeric'

		}
	},
	zip_code_initial: {
		type: 'text',
		title: 'Enter your zip code',
		helper: 'By providing your zip code, we will be able to match you up with licensed mortgage lenders in your area. Not all lenders are licensed in all states, so this will help us match you up with a lender who can help you.',
		validate: scValidate.zip,
		onValid: 'zipCodeLookup',
		onChange: function(c) {
			$e = c.fields.property_zip.$e;
			if ($e) {
				$e.val($(this).val());
			}

		},
		format: scFormat.zip,
		attr: {
			autocomplete: 'postal-code',
			placeholder: 'Zip Code',
			pattern: '[0-9]*',
			inputmode: 'numeric'
		}
	},
	zipcode: {
		type: 'text',
		validate: scValidate.zip,
		format: scFormat.zip,
		attr: {
			autocomplete: 'postal-code',
			placeholder: 'Zip Code',
			pattern: '[0-9]*',
			inputmode: 'numeric'
		}
	}
};
