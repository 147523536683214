scQuestions.vaquiz = {
	1: {
		property_type:          {
			type:    'hidden',
			default: 'Single Family'
		},
		opt_served_military:    {
			title:   'Do you have a Veteran family member that lives with you?',
			options: {
				1: 'Yes, we are a Veteran household.',
				0: 'No, we are not a Veteran household.'
			},
			default: '1',
			helper:  'VA housing benefits for military families means that you could be eligible for zero down financing up to $417,000'
		}
	},
	2: {
		loan_purpose: {
			title:   'Are you interested in purchasing a new home or refinancing an existing home?',
			helper:  false,
			default: 'Refinance',
			options: {
				'Purchase':  'Home Purchase Loan',
				'Refinance': 'Home Refinance Loan'
			}
		}
	},
	3: {
		estimated_down_payment: {
			type:    'hidden',
			default: '5',
			condition: {
				loan_purpose: 'Purchase'
			}
		},
		moving_date:        {
			type:      'select',
			title:     'When are you planning to request your $0 Down VA Loan (Up to $417,000)?',
			condition: {
				loan_purpose: 'Purchase',
			}
		},
		mortgage_amount:    {
			title:     'What is your current loan amount?',
			helper:    false,
			condition: {
				loan_purpose: 'Refinance',
			}
		},
		est_property_value: {
			title:     'What is the estimated value of your home?',
			helper:    'Thank you for your service! We are honored to work with many Veterans just like you - to help them understand their Benefits.',
			condition: {
				loan_purpose: 'Refinance',
			}
		}
	},
	4: {
		est_purchase_price: {
			title:     'How much would you like to borrow? (An estimate is fine...)',
			condition: {
				loan_purpose: 'Purchase',
			}
		},
		mortgage_rate:      {
			title:     'What is your current interest rate?',
			condition: {
				loan_purpose: 'Refinance',
			}
		}
	},
	5: {
		own_rent:          {
			title:     'Do you currently own or rent the place you live?',
			helper:    "Thank you for your service! We are honored to work with many Veterans just like you - to help them understand their Benefits. Whether you decide to purchase your dream home in the near future or not, it's your right to know about this benefit.",
			condition: {
				loan_purpose: 'Purchase',
			}
		},
		current_loan_type: {
			title:     'What is your current loan type?',
			helper:    false,
			default: 'VA',
			options:{
				'VA'   : 'VA',
				'FHA'  : 'FHA',
				'Other': 'Other'
			},
			condition: {
				loan_purpose: 'Refinance',
			}
		}
	},
	6: {
		zip_code_initial: {
			title:  'What is your current zip code that you live in?',
			helper: false,
		},
		property_zip:     {
			title:     'What zip code are you searching in?',
			helper:    false,
			condition: {
				loan_purpose: 'Purchase',
			}
		}
	},
	7: {
		agent_found: {
			title:     'Are you working with a real estate agent?',
			default: '0',
			condition: {loan_purpose: 'Purchase'}
		}
	},
	8: {
		credit_rating: {
			title:    'What is your current credit score to the best of your knowledge?',
			subtitle: '(Most people have GOOD credit)',
			helper:   "This answer does not have to be exact, it's okay to estimate here."
		}
	},
	9: {
		email: {
			title:    'Almost done!',
			subtitle: 'What email address do you want us to send your results and next steps to?',
		}
	},
	10: {
		first_name:    {
			title: 'Enter your information and get your results - fast!',
		},
		last_name:     {},
		address:       {},
		city:          {},
		state:         {
			default: 'Select State...'
		},
		zipcode:       {},
		phone_primary: {},
		phone_email_cert: {}
	}
}