scQuestions.harpeligible = {
	1: {
		loan_purpose: {
			title:  '<h1>Will HARP Save You Money?<i>Find Out Fast With No Obligation</i><h1><h3>Choose a loan type</h3>',
			helper: ''
		}

	},
	2: {
		property_type: {
			title:  'Choose  your property type',
			helper: ''
		}
	},
	3: {
		credit_rating: {
			type:   'radio',
			title:  'Rate Your Credit <span>(Most people have GOOD credit)</span>',
			helper: ''
		}
	},

	4:  {
		zip_code_initial: {
			title:  'Enter your zip code',
			helper: ''
		}
	},
	5:  {
		mortgage_amount: {
			condition: {loan_purpose: 'Refinance'},
			helper:    ''
		},
		own_rent:        {
			condition: {loan_purpose: 'Purchase'},
			type:      'select',
			title:     'Do you currently own or rent?',
			options:   {
				'':     'Please Select',
				'Own':  'Own',
				'Rent': 'Rent'
			}
		}
	},
	6:  {
		est_property_value: {
			condition: {loan_purpose: 'Refinance'},
			helper:    ''
		},
		est_purchase_price: {
			condition: {loan_purpose: 'Purchase'}
		}
	},
	7:  {
		mortgage_rate:          {
			condition: {loan_purpose: 'Refinance'}
		},
		estimated_down_payment: {
			condition: {loan_purpose: 'Purchase'}
		}
	},
	8:  {
		agent_found:       {
			condition: {loan_purpose: 'Purchase'},
		},
		current_loan_type: {
			condition: {loan_purpose: 'Refinance'},
			helper:    ''
		}
	},
	9:  {
		home_found: {
			condition: {loan_purpose: 'Purchase'}
		}
	},
	10: {
		property_zip: {
			condition: {loan_purpose: 'Purchase'}
		}
	},
	11: {
		moving_date: {
			condition: {loan_purpose: 'Purchase'},
			default:   '',
			options:   {
				'':               'Please Select',
				'Right Away':     'Right Away',
				'Within 90 Days': 'Within 90 Days',
				'3-6 Months':     '3-6 Months',
				'6-12 Months':    '6-12 Months',
				'12+ Months':     '12+ Months'
			}
		}
	},
	12: {
		opt_served_military: {
			title:  ' Have you or your spouse served in the military? <span>Veterans may be eligible for special loan programs.</span>',
			helper: ''
		}
	},
	13: {
		first_name: {},
		last_name:  {},
		address:    {},
		city:       {},
		state:      {},
		zipcode:    {}
	},
	14: {
		email:         {},
		phone_primary: {},
		phone_email_cert: {}
	}
};
