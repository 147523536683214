scQuestions.autoform = {
	1: {
		loan_purpose: {
			title: 'Type of Loan',
			type: 'radio',
			default: false,
			helper: false,
			options: {
				'Refinance': 'Refinance',
				'New Car Purchase': 'New Car Purchase',
				'Used Car Purchase': 'Used Car Purchase'
			},
			onChange: scOptions.onChangeAutoLoanPurpose
		}
	},
	2: {
		loan_balance: {
			title: 'What is your current loan balance?',
			type: 'text',
			condition: {
				loan_purpose: 'Refinance'
			},
			class: 'is-currency',
			attr: {
				placeholder: 'Current Balance'
			}
		},
		payment: {
			title: 'What is your current car payment?',
			type: 'text',
			condition: {
				loan_purpose: 'Refinance'
			},
			attr: {
				placeholder: 'Current Payment'
			}
		},
		credit_rating: {
			title: 'Rate Your Credit',
			subtitle: '(please rate your credit as accurately as possible)',
			condition: {
				loan_purpose: [
					'New Car Purchase',
					'Used Car Purchase'
				]
			}
		}
	},
	3:{
		rate: {
			title: 'Select your current interest rate',
			type: 'slider',
			default: 5.00,
			css: {
				margin: '1em auto'
			},
			condition: {
				loan_purpose: 'Refinance'
			},
			options: scOptions.autoRates
		},
	},
	4: {
		vehicle_make: {
			title: 'Vehicle Information',
			condition: {
				loan_purpose: [
					'New Car Purchase',
					'Used Car Purchase',
					'Refinance'
				]
			}
		},
		vehicle_model: {
			condition: {
				loan_purpose: [
					'New Car Purchase',
					'Used Car Purchase',
					'Refinance'
				]
			}
		},
		vehicle_year: {
			condition: {
				loan_purpose: [
					'New Car Purchase',
					'Used Car Purchase',
					'Refinance'
				]
			}
		},
		zip_code_initial: {
			helper: false,
			condition: {
				loan_purpose: [
					'New Car Purchase',
					'Used Car Purchase',
					'Refinance'
				]
			}
		},
	},
	5: {
		credit_rating: {
			title: 'Rate Your Credit',
			subtitle: '(please rate your credit as accurately as possible)',
			condition: {
				loan_purpose: [
					'Refinance'
				]
			}
		}
	},
	6: {
		first_name: {
			type: 'text',
			attr: {
				placeholder: 'First Name*'
			}
		},
		last_name: {
			type: 'text',
			attr: {
				placeholder: 'Last Name*'
			}
		},
		address: {
			type: 'text',
			attr: {
				placeholder: 'Current Address*'
			}
		},
		city: {
			type: 'text',
			attr: {
				placeholder: 'City*'
			}
		},
		state: {
			type: 'text',
			attr: {
				placeholder: 'State*'
			}
		},
		zipcode: {
			attr: {
				placeholder: 'Zip Code*'
			}
		},
	},
	7: {
		email: {
			type: 'text',
			attr: {
				placeholder: 'Email*'
			},
			validate: scValidate.email
		},
		phone_primary: {
			type: 'text',
			attr: {
				placeholder: 'Primary Phone*'
			}
		},
		phone_email_cert: {}
	}
};