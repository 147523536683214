scQuestions.rent2ownqualifiedrenttoown = {
		1: {
			first_name: {
				title:'First Name',
				attr:     {
					placeholder:  '',
				}
			},
	  		last_name:  {
            	title:'Last Name',
				attr:     {
                  placeholder:  '',
                }
            },
			email:		{
            	title: 'Email',
				attr:     {
                  placeholder:  '',
                }
            },
			phone_primary: {
            	title:'Primary Phone',
				attr:     {
                  placeholder:  '',
                }
            },
			zipcode:    {
            	title: 'Zipcode',
				attr:     {
                  placeholder:  '',
                }
            },
          	mortgage_amount:  {
	            title:'Monthly Income:',
	            helper: '',
	          	type: 'select',
				attr:     {
                  placeholder:  '',
                }
          	},
			property_type: {
				title:   'Property Type:',
	        	helper:  '',
				type: 'select',
				attr:     {
                  placeholder:  '',
                }
	        },
			credit_rating: {
	        	helper:  '',
				title: 'Credit Rating:',
				type: 'select',
				attr:     {
                  placeholder:  '',
                }
	        },
			opt_served_military: {
	        	helper:  '',
                type:  'select',
				attr:     {
                  placeholder:  '',
                }
	        }
        }
};
