//Required: sc-fields.js, jquery-ui.js, newrelic.js
site_config = site_config || {};

if (!scFields) {
	console.error("scFields is missing! Please include the scFields file before this file.");
} else {
	site_config.fields = $.extend(true, site_config.fields || {}, scFields);
}

var scForm = {
	current: 1,
	total: 0,
	isPopup: false,
	trackFB: typeof fbq === 'function',
	fieldsViewed: {},
	fieldConditions: {},
	$primaryForm: null,
	$slider: null,
	slideTransitionTime: 200,

	isLastField: function($form) {
		return !$form.closest('.input-holder').nextAll('.input-holder:visible').length;
	},

	addField: function(name, value, id, type) {
		if (scForm.$primaryForm) {
			scForm.$primaryForm
				.append($(type || '<input/>')
					.attr('type', 'hidden')
					.attr('name', base64_encode(name))
					.attr('data-name', name)
					.attr('id', id).val(value));
		}
		return scForm;
	},

	configureSite: function() {
		site_config = $.extend(true, {
			pageLoadId: Math.random() * 10000,
			domain: '//varatelock.com',
			apiUrl: '/api/writelog',
			postUrl: '/signups/submit_instapage',
			privacyUrl: '/pages/display/privacy_ec',
			trustedForm: true,
			showFormFooter: true,
			resizeBackground: true,
			mortgageRateSlider: true,
			downPaymentCalc: true,
			progressBar: true,
			bodyClass: 'sc-standard',
			helperAfterTitle: false,
			controlsAtTop: false,
			submitText: 'Submit',
			nextText: 'Next',
			preloadThankYouPage: true,
			cake: {
				params: {
					a: 52
				}
			}
		}, site_config);

		site_config.apiUrl = site_config.domain + site_config.apiUrl;
		site_config.postUrl = site_config.domain + site_config.postUrl;
		site_config.privacyUrl = site_config.domain + site_config.privacyUrl;
	},

	initSignup: function() {
		if (!scForm.$primaryForm) return;

		site_config.site_id = window.__page_id;
		site_config.site_name = document.title;
		site_config.site_url = location.href.replace(/\?.*/, '') + '?variant=' + encodeURIComponent(scForm.$primaryForm.find('[name=variant]').val());
		site_config.ts = getQueryParam('ts') || '';

		if (site_config.ts.match(/_/)) {
			var parts = site_config.ts.split(/:(.+)?/, 2);
			site_config.ts = parts[0];
			site_config.prior_id = parts[1];
		}

		var storedSiteConfig = $.extend({}, site_config);
		delete storedSiteConfig.fields;
		storedSiteConfig = JSON.stringify(storedSiteConfig);

		scForm.addField('site_id', site_config.site_id)
			.addField('site_name', site_config.site_name)
			.addField('site_url', site_config.site_url)
			.addField('universal_leadid', '', 'leadid_token')
			.addField('traffic_source', site_config.ts)
			.addField('prior_id', site_config.prior_id)
			.addField('request_id', site_config.request_id, 'ckm-request-id')
			.addField('site_config', storedSiteConfig);

		if (!scForm.$thankYouPage && scForm.isLastStep()) {
			scForm.buildThankYouPage();
		}

		$(document).ready(function() {
			if (!scForm.$primaryForm.find('[name=redirect]').val()) {
				return scFlash.displayError("It looks like a redirect hasn't been set up for this site. In the site builder, click the main button, click Edit, then click Redirect on the top menu bar.");
			}
			scForm.setDefaults();
			//Down Payment Calculator
			//if (site_config.downPaymentCalc) scForm.addDownPaymentCalculator();
			if (site_config.progressBar) scForm.addProgressBar();
			if (site_config.mortgageRateSlider) scForm.renderMortgageRateSlider();
			scForm.show(1);
		});
	},

	zipCodeLookup: function(val) {
		$.get(site_config.domain + '/api/zipcode/' + val, {}, function(response) {
			if (response.city) {
				var $zip = $('<div/>').addClass('sc-zip-lookup');
				$zip.append($('<div/>').addClass('zip-location').html(response.city + ', ' + response.state + ' ' + val))
					.append($('<a/>').addClass('zip-change').html('Change').click(function() {
						scForm.$primaryForm.find('.is-zip-lookup').show();
						scForm.$primaryForm.find('.sc-zip-lookup').remove();
						scForm.resize();
					}));

				scForm.$primaryForm.find('.sc-zip-lookup').remove();
				scForm.$primaryForm.find('[data-name=city]').val(response.city)
					.add(scForm.$primaryForm.find('[data-name=state]').val(response.state))
					.add(scForm.$primaryForm.find('[data-name=zipcode]').val(val))
					.closest('.input-holder').addClass('is-zip-lookup').hide().first().before($zip);
			}
		}, 'json');
	},

	addDownPaymentCalculator: function() {
		var $calculator = scForm.$primaryForm.find('[data-name=estimated_down_payment]'),
			$pp = scForm.$primaryForm.find('[data-name=est_purchase_price]'),
			$calculatorAmount = $('<div/>').addClass('sc-dp-amount sc-input'),
			$calculatorInput = $('<input type="hidden" />'),
			$calcBox = $('<div/>').addClass('sc-dp-calc');

		if ($calculator.attr('type') !== 'hidden') {
			$calcBox
				.append($('<div/>').addClass('sc-title').html('Estimated Down Payment Amount'))
				.append($calculatorAmount)
				.append($calculatorInput);

			$calculator.closest('.input-holder').append($calcBox);

			$calculator.change(function() {
				var amount = parseInt($calculator.val()) * $pp.val() / 100;
				$calculatorAmount.html('$' + amount.currency(0));
				$calculatorInput.val(amount);
			}).change();
		}
	},

	addProgressBar: function() {
		var $wf = $('.sc-form.is-primary');
		var barClass = typeof site_config.progressBar === 'string' ? site_config.progressBar : 'top';
		scForm.$progress = $('<div/>').addClass('sc-progress-bar sc-' + barClass).progressbar();
		if (site_config.progressBar === 'bottom') {
			$wf.find('.contents').append(scForm.$progress);
		} else {
			$wf.prepend(scForm.$progress);
		}
	},

	passFormDataToIframe: function(data) {
		// The thankyou page is loaded in an iframe - see the readme for details
		// This method allows submitted form data to be passed in to the iframe, so that
		// form data is available to populate the thankyou links. This allows us to prepopulate
		// the pages a user goes to after the thankyou page.
		var iframeWindow = window.frames['thankyou-iframe'],
			// `urlParser` below is just a clever way of parsing a URL string using the DOM. The API for `a`
			// elements in JS offers the `.origin` property, which parses out the origin from the element's
			// `href`.
			urlParser = document.createElement('a');
		urlParser.href = scForm.getRedirect();
		var targetOrigin = urlParser.origin;
		iframeWindow.postMessage(data, targetOrigin);
	},

	submit: function() {
		if (!scForm.isLastStep()) {
			console.error('Submit called before last step - not submitting');
			return false;
		}

		var data = scForm.getFormData(scForm.$primaryForm);

		if (site_config.trustedForm) {
			data.xxTrustedFormToken = scForm.$primaryForm.find('[name="xxTrustedFormToken"]').val();
			data.xxTrustedFormCertUrl = scForm.$primaryForm.find('[name="xxTrustedFormCertUrl"]').val();
		}

		data.SR_TOKEN = scForm.$primaryForm.find('[name="SR_TOKEN"]').val();

		//Facebook Pixel Tracking
		if (scForm.trackFB) fbq('track', 'Lead');

		//Must show before submitting the form (this removes the redirect for instapage)
		scForm.showThankYouPage();
		scForm.allowSubmission();
		scForm.$primaryForm.submit();

		// Hand the form data off to the thankyou iframe for prepopulation of thankyou links
		scForm.passFormDataToIframe(data);

		$.post(site_config.postUrl, data, function (response) {
			if (response && response.hasOwnProperty('success') && response["success"] && response.hasOwnProperty('uu') && window.xdc) {
				window.xdc.setRemote(response['uu']);
			}
		}, 'json');
	},

	allowSubmission: function() {
		//XXX: removes the hack to prevent instapage form submission before we are ready
		$('#sc-require').remove();
	},

	preventSubmission: function($form) {
		//XXX: This prevents instapage from submitting the form before we are ready
		$form.append("<input type='hidden' id='sc-require' name='required' class='require required' value='' />");
	},

	showThankYouPage: function() {
		if (!scForm.$thankYouPage) scForm.buildThankYouPage();
		var $body = $('body').scrollTop(0);
		// Set the positioning of the thankyou page div
		// If the first section is over 200px high, just set it at the top.
		// In most pages, the top section is the header, but on some the designers just throw everything
		// into one section.
		var top = $('.page-block-1').outerHeight();
		if (top > 200) top = 0;
		scForm.$thankYouPage.addClass('is-showing').css('top', top);
		$('html').css({
			height: 'auto',
			overflow: 'hidden'
		});
		$(document.activeElement).blur();
		$body.focus().blur().addClass('is-showing-thank-you');
	},

	buildThankYouPage: function() {
		if (!scForm.$thankYouPage) {
			var redirect = scForm.getRedirect();
			if (redirect) {
				scForm.$thankYouPage = $('#sc-thank-you-page');
				scForm.$thankYouPage.find('iframe').attr('src', redirect + '&hide_header=1');
			}
		}
	},

	getFormData: function($form) {
		var data = {};
		$form.find('[data-name]').each(function(i, element) {
			var $element = $(element);
			if ($element.closest('.failed-condition').length) return;
			var name = $element.attr('data-name'),
				value = scForm.getFieldValueByElement($element);

			//Format Data
			if (site_config.fields[name] && site_config.fields[name].format) {
				value = site_config.fields[name].format(value);
			}

			switch ($element.attr('type')) {
				case 'checkbox':
					if ($element.prop('checked')) {
						if (value !== '') {
							if (!data[name]) data[name] = [];
							data[name].push(value);
						}
					} else if (typeof (value = $element.attr('data-unchecked-value')) !== 'undefined') {
						data[name] = value;
					}
					break;
				case 'radio':
					if (!$element.is(':checked')) return;
				/* falls through */
				default:
					data[name] = value;
					break;
			}
		});
		return data;
	},

	getRedirectUrl: function() {
		var redirect = site_config.redirect,
			url;

		switch (typeof redirect) {
			case 'string':
				url = redirect;
				break;
			case'object':
				for (var r in redirect) {
					if (redirect.hasOwnProperty(r)) {
						if (r === 'default') {
							url = url || redirect[r];
						} else if (scForm.isCondition(redirect[r].condition)) {
							url = redirect[r].url;
						}
					}
				}
				break;
		}

		url = url || scForm.redirect || '';

		if (!url) {
			url = scForm.$primaryForm.find('[name=redirect]').val() || '';
			urlQueryParam = getQueryParam('url', url);
			if (urlQueryParam) url = '//' + urlQueryParam;
			if (!url) {
				return scFlash.displayError("It looks like a redirect hasn't been set up for this site. In the site builder, click the main button, click Edit, then click Redirect on the top menu bar.");
			}
		}

		return url;
	},

	getRedirect: function() {
		var url = scForm.getRedirectUrl();

		var post = scForm.getFormData(scForm.$primaryForm);

		var params = {
			site_id: site_config.site_id,
			logo: site_config.logo,
			ts: getQueryParam('ts'),
			zipcode: post.zipcode || post.property_zip,
			credit_rating: post.credit_rating,
			loan_purpose: post.loan_purpose,
			est_purchase_price: post.est_purchase_price,
			mortgage_amount: post.mortgage_amount
		};

		url += (url.indexOf('?') > 0 ? '&' : '?') + $.param(params);

		//Remove the redirect for instapage (we redirect manually)
		scForm.$primaryForm.find('[name=redirect]').remove();
		scForm.redirect = url;
		return url;
	},

	isCondition: function(condition) {
		if (condition) {
			for (var fieldName in condition) {
				if (condition.hasOwnProperty(fieldName)) {
					var value = scForm.getFieldValue(fieldName);

					if (Array.isArray(condition[fieldName])) {
						var pass = false;
						for (var i in condition[fieldName]) {
							if (condition[fieldName].hasOwnProperty(i)) {
								if (condition[fieldName][i] == value) pass = true;
							}
						}
						if (!pass) return false;
					} else if (value != condition[fieldName]) {
						return false;
					}
				}
			}

			return true;
		}
	},

	getFieldValue: function(name) {
		var $field = $('[data-name=' + name + ']');
		return scForm.getFieldValueByElement($field);
	},

	getFieldValueByElement: function($field) {
		var type = $field.attr('type');
		if (type === 'radio') {
			return $field.filter(':checked').val();
		} else if (type === 'checkbox') {
			return $field.is(':checked') ? $field.val() : '';
		} else {
			return $field.val();
		}
	},

	checkConditions: function() {
		var $conditions = $(this).find('[data-conditional]');
		$conditions.removeClass('failed-condition').each(function(i, element) {
			var $element = $(element);
			var n = $element.attr('data-conditional');
			if (!scForm.isCondition(scForm.fieldConditions[scForm.current][n])) {
				$element.addClass('failed-condition');
			}
		});
	},

	getSlideIndex: function(event) {
		var slideIndex;
		if (event && event.target) {
			slideIndex = +$(event.target).closest('.sc-slide').attr('data-slide') || scForm.current;
		} else {
			slideIndex = scForm.current;
		}
		return slideIndex;
	},

	next: function(event) {
		var slideIndex = scForm.getSlideIndex();
		if (Date.now() - scForm.next.last < 300) return;
		var isValid = scForm.validate(slideIndex);
		if (isValid) {
			if (scForm.isLastStep()) {
				scForm.submit();
			} else if (scForm.validate(slideIndex)) {
				scForm.show(slideIndex + 1);
			}
		}

		scForm.next.last = Date.now();
	},

	prev: function(event) {
		var slideIndex = scForm.getSlideIndex();
		scForm.show(slideIndex - 1, -1);
	},

	show: function(slideIndex, direction) {
		if (slideIndex > 0 && slideIndex <= scForm.total && scForm.$primaryForm.is(':visible')) {
			scForm.current = slideIndex;
			scForm.$slider.css({left: (-(slideIndex - 1) * 100) + '%'});
			scForm.$primaryForm
				.removeClass('show-last show-first')
				.find('.sc-btn-next')
				.html(slideIndex >= scForm.total ? site_config.submitText : site_config.nextText);

			var formClass = '';
			if (slideIndex === 1) formClass += 'show-first';
			if (slideIndex === scForm.total) formClass += ' show-last';
			scForm.$primaryForm.addClass(formClass);
			var $slide = scForm.getCurrentSlide();
			scForm.checkConditions.call($slide);

			if (!$slide.find('.input-holder:visible').length) {
				scForm.show(slideIndex + (direction || 1), direction);
			} else {
				if (site_config.preloadThankYouPage && scForm.isLastStep()) {
					scForm.buildThankYouPage();
				}

				scForm.resize();
				if (scForm.$progress) scForm.$progress.progressbar('value', 100 * scForm.current / scForm.total);
				//Set focus after animations have time to make element visible
				setTimeout(function() {
					var $fields = $slide.find('[name]:visible');
					$fields.first().focus();
					$slide.trigger('sc-show');
				}, scForm.slideTransitionTime);
			}
		}
	},

	resize: function(scroll) {
		var newSliderHeight = scForm.get(scForm.current).outerHeight();

		scForm.$slider.height(newSliderHeight);

		if (scForm.isPopup) {
			scForm.$popup.scrollTo(0);
		} else {
			if (!scForm.formHeight) {
				scForm.formHeight = scForm.$primaryForm.height();
				scForm.borderHeight = scForm.$border.height();
			} else if (site_config.resizeBackground) {
				scForm.$border.height(scForm.borderHeight + (scForm.$primaryForm.height() - scForm.formHeight + (newSliderHeight - scForm.$slider.height())));
			}

			var isAtTop = $('body').scrollTop() <= scForm.$primaryForm.offset().top;
			var isMultiPageForm = scForm.total > 1;
			if (scroll !== false && !isAtTop && isMultiPageForm) {
				$('body,html').scrollTo(scForm.$primaryForm, {offset: -40});
			}
		}
	},

	validate: function(slide) {
		$slide = scForm.get(typeof slide != 'object' ? slide : scForm.current);
		if ($slide) {
			var isSlideValid = true;

			$slide.find('.input-holder.is-required').not('.failed-condition, .field-hidden').each(function(i, element) {
				var $element = $(element);
				$element.find('.sc-error').remove();
				var $inputs = $element.find('[data-name]'),
					name = $inputs.attr('data-name'),
					field = site_config.fields[name] || site_config.fields.default,
					conditions = field.validate || site_config.fields.default.validate,
					value = $element.getVal(name);

				//This is for vehicle_dealers when there are no dealers, user cannot select anything
				if ($element.hasClass('no-dealers')) return;

				for (var d in conditions) {
					if (conditions.hasOwnProperty(d)) {
						var isFieldValid = true,
						cond = conditions[d];

						if (Array.isArray(value)) {
							if (!cond.empty && !value.length) {
								isFieldValid = false;
							}
						} else {
							if (!cond.empty && !value) {
								isFieldValid = false;
							} else if (cond.regex && !value.match(cond.regex)) {
								isFieldValid = false;
							}
						}

						if (isFieldValid && cond.callback) {
							isFieldValid = cond.callback(value);
						}

						if (!isFieldValid) {
							scForm.showError($element, cond.msg);
							isSlideValid = false;
							return false;
						}
					}
				}

				field.$element.trigger('sc-selected');

				if (field.onValid) {
					if (typeof field.onValid === 'function') {
						field.onValid.call($inputs, value);
					} else {
						scForm[field.onValid].call($inputs, value);
					}
				}
			});

			return isSlideValid;
		}
	},

	isLastStep: function() {
		return scForm.getSlideIndex() >= scForm.total;
	},

	onChange: function(event) {
		var $target = $(event.target);
		var field = site_config.fields[$target.attr('data-name')];

		if (!$target.attr('data-stay-on-change') && scForm.isLastField($target) && !scForm.isLastStep()) {
			scForm.next.call(this, event);
		} else {
			scForm.checkConditions.call(scForm.$slider.find('[data-slide=' + scForm.current + ']'));
			scForm.resize();
		}

		if (field.onChange) {
			field.onChange.call($target, site_config);
		}
	},

	showError: function($element, msg) {
		$element
			.find('.field-element:last')
			.append($('<div/>')
				.addClass('sc-error')
				.html(msg))
			.find('[name]')
			.addClass('form-validation-error')
			.focus();
		scForm.resize(false);
		$('body,html').scrollTo($element);
	},

	get: function(slideIndex) {
		$slide = scForm.$slider.find('[data-slide=' + slideIndex + ']');
		if ($slide.length) {
			return $slide;
		} else {
			return false;
		}
	},

	setDefaults: function() {
		scForm.$primaryForm.find('[data-default]').each(function() {
			var $element = $(this);
			switch ($element.attr('type')) {
				case 'radio':
				case 'checkbox':
					$element.prop('checked', true).attr('checked', 'checked');
					break;

				default:
					$element.val($element.attr('data-default'));
					break;
			}
		});
	},

	replaceLocationVars: function(location) {
		var $replace = $('.page-block').find('p, .field-title').filter(':contains("%city%"),:contains("%zip%"),:contains("%state%")');

		$replace.each(function() {
			var $this = $(this);
			$this.html(
				$this.html()
					.replace('%city%', location ? location.city.names.en : 'Your City')
					.replace('%zip%', location ? location.postal.code : "Your Area")
					.replace('%state%', location ? location.subdivisions[0].iso_code : "Your State")
			);
		});

		if (location) {
			scForm.addField('lat_long', location.location.latitude + ',' + location.location.longitude);
			scForm.addField('geo_state', location.subdivisions[0].iso_code);
			scForm.addField('geo_city', location.city.names.en);
			scForm.addField('geo_zip', location.postal.code);

			if (scForm.$primaryForm) {
				scForm.$primaryForm
					.find('[data-name=zipcode],[data-name=zip_code], [data-name=zip_code_initial]')
					.val(location.postal.code);
			}
		}
	},


	renderMortgageRateSlider: function() {
		if (($rate = scForm.$primaryForm.find('[data-name=mortgage_rate]')).length) {
			var $jqSlider = $('<div/>').attr('id', 'jq-slider').attr('class', 'sc-jq-slider')
				.slider({
					value: parseFloat($rate.val()),
					min: 2.75,
					max: 8,
					step: 0.25,
					range: 'min',
					slide: function(e, ui) {
						$rate.val(parseFloat(ui.value)).change();
					}
				});
			$rate
				.after($jqSlider)
				.addClass('sc-mortgage-rate')
				.attr('maxlength', 6)
				.on('focus', function() {
					$(this).blur();
				})
				.keyup(function() {
					var value = $rate.val();
					if (value) {
						value = value.replace(/[^0-9.]/, '').replace(/([0-9]*\.[0-9]*)\..*/, '$1');
						valueFloat = parseFloat(value);
						if (!valueFloat || valueFloat <= 0) value = 0;
						if (valueFloat > 10) value = 10;
						$rate.val(value);
						$jqSlider.slider('value', value || 0);
					}
				})
				.change(function() {
					newValue = $(this).val().replace('%', '') + '%';
					$(this).val(newValue);
				});

			$rate.closest('.sc-slide').on('sc-show', function() {
				if (!$rate.val()) $rate.val(site_config.fields.mortgage_rate.default);
				$jqSlider.slider('value', parseFloat($rate.change().val()));
			});
		}
	},

	buildModal: function(content, shouldShow) {
		var $modal = $('<div/>').addClass('sc-modal'),
			$box = $('<div/>').addClass('sc-modal-box'),
			$center = $('<div/>').addClass('sc-modal-center'),
			$content = $('<div/>').addClass('sc-modal-content'),
			$header = $('<div/>').addClass('sc-modal-header'),
			$body = $('<div/>').addClass('sc-modal-body'),
			$overlay = $('<div/>').addClass('sc-modal-overlay'),
			$close = $('<div/>').addClass('sc-modal-close');

		$content.append($header).append($body).append($close);
		$box.append($center).append($content);
		$modal.append($overlay).append($box);

		$overlay.add($close).click(function() {
			$modal.removeClass('is-showing');
			$('body').removeClass('is-showing-modal');
		});

		if (content) $modal.find('.sc-modal-body').html(content);
		if (shouldShow) {
			$modal.addClass('is-showing');
			$('body').addClass('is-showing-modal');
		}
		return $modal.appendTo('body');
	},

	showModal: function(url) {
		if (!scForm.$modal) scForm.$modal = scForm.buildModal();

		$.get(url, null, function(content) {
			var $content = $(content);
			var $body = scForm.$modal.find('.sc-modal-body').html($content);
			scForm.$modal.find('.sc-modal-header').html($body.find('h1, h2').first());
			scForm.$modal.addClass('is-showing');
			$('body').addClass('is-showing-modal');

			$body.find('a, img').each(function(i, element) {
				var $element = $(element);
				var attr = $element.is('img') ? 'src' : 'href';
				$element.attr(attr, ($element.attr(attr) || '').replace(/^[/]/, site_config.domain + '/'));
			});

			if (site_config.info) {
				for (var i in site_config.info) {
					if (site_config.info.hasOwnProperty(i)) {
						$body.find('.sc-' + i).html(site_config.info[i]);
					}
				}
			}
		});
	},

	buildSlider: function() {
		var $form = $(this);
		$form.find('.input-holder').remove();

		var $slider = $('<div />').addClass('sc-slider'),
			$viewer = $('<div />').addClass('sc-viewer'),
			$controls = $('<div/>').addClass('sc-controls'),
			$prevBtn = $('<div />').addClass('sc-btn sc-btn-prev').html('Back').click(scForm.prev),
			$nextBtn = $('<div/>').addClass('sc-btn sc-btn-next').html('Next').click(scForm.next);

		$(document).keyup(function(event) {
			if (event.keyCode === 13) {
				if (!scForm.isLastStep()) scForm.next();
				event.preventDefault();
				return false;
			}
		}).keydown(function(event) {
			if (event.keyCode === 9 && scForm.isLastField($(event.target))) {
				if (!scForm.isLastStep()) scForm.next();
				event.preventDefault();
				return false;
			}

			if (event.keyCode === 13) {
				event.preventDefault();
				return false;
			}
		});

		scForm.preventSubmission($form);

		scForm.$primaryForm = $form
			.addClass('show-first')
			.change(scForm.onChange)
			.append($viewer.append($slider))
			.append($controls.append($nextBtn).append($prevBtn));

		if (site_config.controlsAtTop) {
			$form.prepend($controls.clone(true));
		}

		if (site_config.showFormFooter) {
			var $formFooter = $('<div/>').addClass('sc-form-footer');
			$formFooter.append($('<img/>').attr('src', '//s3-us-west-1.amazonaws.com/suitedconnector.com/img/7000348-0-accredit.png'));
			$form.append($formFooter);
		}

		// Set slide transition time
		$slider.css('transition', 'all ' + scForm.slideTransitionTime / 1000 + 's');

		return $slider;
	},

	buildInput: function(name, field) {
		if (field.status === false) return;

		field.name = name;
		field.name64 = base64_encode(name);

		var $holder = $('<div/>')
			.attr('data-type', field.type)
			.addClass('input-holder field-' + field.type)
			.addClass(field.class || '')
			.addClass('name-' + field.name);
		var $title = field.title ? $('<div/>').addClass('field-title outside top sc-title').html(field.title) : false;
		var $subtitle = field.subtitle ? $('<div/>').addClass('field-title outside top sc-subtitle').html(field.subtitle) : false;
		var $input = $('<div/>').addClass('sc-input');
		var $helper;
		if (field.helper) {
			$helper = $('<div/>').addClass('sc-helper').html($('<div />').addClass('text').html(field.helper));
		} else {
			$helper = false;
		}

		if (!site_config.fields[name]) site_config.fields[name] = {};

		//Add Title & Subtitle if set
		if ($title) $holder.append($title);
		if ($subtitle) $holder.append($subtitle);

		$holder.append($input);

		if (field.options) {
			/*If field options is a function call, call that function
			 If the return value is set, the options have been filled synchronously
			 If the return value is null, the options are asynchronously filled, and the function should
			 call the callback with options as a parameter. */
			if (typeof field.options === 'function') {
				field.options = field.options.call($input, scForm.$primaryForm, function(opts) {
					field.options = opts;
					scForm.fillOptions.call($input, field);
				});
			}
			if (field.options) scForm.fillOptions.call($input, field);
		} else {
			scForm.fillOptions.call($input, field);
		}

		//Add Helper box
		if ($helper) {
			if (site_config.helperAfterTitle) {
				$title.append($helper);
			} else {
				$holder.append($helper);
			}
		}

		if (field.css) $holder.css(field.css);
		if (field.condition) $holder.attr('data-conditional', name);
		if (field.validation !== false) $holder.addClass('is-required');
		if (field.onBuild) field.onBuild.call($holder, site_config, scForm);
		return $holder;
	},

	onClickRadioInput: function() {
		$input = $(this);
		$input.prop('checked', true).change();
		//XXX: There is something (presumably instapage scripts) that is allowing radio options to be unchecked)
		setTimeout(function() { $input.prop('checked', true); }, 200);
	},

	parseOptions: function(fieldOptions) {
		// Takes the specified `options` in field definitions - for example, those in `sc-fields.js`
		// Returns an array of objects containing `value: label` pairs for <option> tags
		var parsedOptions = [], option;
		if (Array.isArray(fieldOptions)) {
			for (option in fieldOptions) {
				if (fieldOptions.hasOwnProperty(option)) {
					// In field definitions, field options can be specified as an array of strings, e.g.,
					// ['1:First option', '2:Second option']
					// The number before the colon is the value, and after the colon is the label.
					if (typeof fieldOptions[option] !== 'object') {
						var parts = fieldOptions[option].split(/:(.+)?/, 2);
						parsedOptions.push({
							label: parts[1] || parts[0],
							value: parts[0]
						});
					}
				}
			}
		} else {
			for (option in fieldOptions) {
				if (fieldOptions.hasOwnProperty(option)) {
					parsedOptions.push({
						label: fieldOptions[option],
						value: option
					});
				}
			}
		}
		return parsedOptions;
	},

	fillOptions: function(field) {
		var $inputBox = $(this),
			options = scForm.parseOptions(field.options);
		field.default = getQueryParam(field.name) || field.default;

		switch (field.type) {
			case 'hidden':
				scForm.buildHiddenInput(field, $inputBox);
				break;
			case 'checkbox':
			case 'radio':
				scForm.buildCheckboxOrRadio(field, $inputBox, options);
				break;
			case 'slider':
				scForm.buildSliderInput(field, $inputBox, options);
				break;
			case 'select':
				scForm.buildSelect(field, $inputBox, options);
				break;
			case 'text':
			/* falls through */
			default:
				scForm.buildTextInput(field, $inputBox);
				break;
		}
	},

	buildHiddenInput: function(fieldDefinition, $inputBox) {
		$input = $('<input/>').attr({
			type: 'hidden',
			name: fieldDefinition.name64,
			'data-name': fieldDefinition.name,
			value: fieldDefinition.default || ''
		});
		$inputBox.append($input);
	},

	buildCheckboxOrRadio: function(fieldDefinition, $inputBox, options) {
		var $options = $('<div/>').addClass('sc-options');

		for (var o in options) {
			if (options.hasOwnProperty(o)) {
				var opt = options[o],
				id = 'sc-' + opt.value.replace(/[^a-z0-9]+/gi, '-') + '-' + (new Date().valueOf()),
				$fieldElement = $('<div/>').addClass('field-element'),
				$label = $('<label/>').attr('for', id).html(opt.label),
				$input = $('<input/>')
					.attr({
						type: fieldDefinition.type,
						name: fieldDefinition.name64,
						'data-name': fieldDefinition.name,
						id: id,
						value: opt.value
					})
					.attr(fieldDefinition.attr || {});

				if (fieldDefinition.type === 'checkbox') {
					$input.attr('data-stay-on-change', '1');
				} else {
					$input.click(scForm.onClickRadioInput);
				}

				//Set this as the default value if there is no default value
				if (!fieldDefinition.default) fieldDefinition.default = opt.value;

				if (fieldDefinition.default !== false) {
					var isDefault;
					if (Array.isArray(fieldDefinition.default)) {
						isDefault = fieldDefinition.default[opt.value];
					} else {
						isDefault = opt.value == fieldDefinition.default;
					}
					if (isDefault) {
						$input.prop('checked', true).attr('data-default', 1);
					}
				}

				$fieldElement.append($input).append($label);
				$options.append($fieldElement);
			}
		}

		$inputBox.append($options);
		site_config.fields[fieldDefinition.name].$element = $options.find('[data-name]');
	},

	buildSliderInput: function(fieldDefinition, $inputBox, options) {
		var $fieldElement = $('<div/>').addClass('field-element'),
			$label = $('<div/>').addClass('ui-slider-label'),
			sliderDefault = 0,
			$hidden = $('<input/>').attr({
				type: 'hidden',
				name: fieldDefinition.name64,
				'data-name': fieldDefinition.name,
				value: fieldDefinition.default
			});

		for (var o in options) {
			if (options.hasOwnProperty(o)) {
				if (fieldDefinition.default == options[o].value) {
					sliderDefault = o;
				}
			}
		}

		var slideCallback = function(e, ui) {
			var s = options[ui.value];

			if (s) {
				$label.html(s.label);
				$hidden.val(s.value);
			}
		};

		var $slider = $('<div/>').addClass('sc-jq-slider').slider({
			value: sliderDefault,
			min: 0,
			max: options.length - 1,
			step: 1,
			range: 'min',
			slide: slideCallback
		});

		//Sets the default and calls the slide callback
		slideCallback(0, {value: sliderDefault});
		$fieldElement.append($label).append($hidden).append($slider);
		$inputBox.append($fieldElement);
		site_config.fields[fieldDefinition.name].$element = $hidden;
	},

	buildSelect: function(fieldDefinition, $inputBox, options) {
		var $fieldElement = $('<div/>').addClass('field-element'),
			$select = $('<select/>')
				.attr({
					name: fieldDefinition.name64,
					'data-name': fieldDefinition.name
				})
				.attr(fieldDefinition.attr || {});

		for (var option in options) {
			if (options.hasOwnProperty(option)) {
				$select.append($('<option/>').attr('value', options[option].value).html(options[option].label));
			}
		}

		$select.val(fieldDefinition.default || '');
		if (fieldDefinition.hideEmptyOption) $select.find('option[value=""]').hide();
		$fieldElement.append($select);
		$inputBox.append($fieldElement);
		site_config.fields[fieldDefinition.name].$element = $select;
	},

	buildTextInput: function(fieldDefinition, $inputBox, id) {
		var $fieldElement = $('<div/>').addClass('field-element'),
			$input = $('<input/>')
				.addClass('form-input')
				.attr({
					type: 'text',
					name: fieldDefinition.name64,
					'data-name': fieldDefinition.name,
					value: fieldDefinition.default,
					'data-default': fieldDefinition.default || ''
				})
				.attr(fieldDefinition.attr || {});

		if (fieldDefinition.format) {
			$input.keyup(function() { scForm.formatInput(this, fieldDefinition.format) });
		}

		$inputBox.append($fieldElement.append($input));
		site_config.fields[fieldDefinition.name].$element = $input;
	},

	formatInput: function(input, formatFunction) {
		var $input = $(input),
			oldVal = $input.val(),
			newVal = formatFunction.call($input, oldVal);
		$input.val(newVal);
	},

	initializeFooterModalLinks: function() {
		var $disclaimer = $('#sc-disclaimer, .sc-disclaimer-links');

		if ($disclaimer.length) {
			$disclaimer.find('a.sc-popup').each(function(i, element) {
				var $element = $(element);
				$element.attr('data-href', $element.attr('href').replace(/^%URL%/, site_config.domain)).attr('href', '#');

				$element.click(function(ev) {
					ev.preventDefault();
					scForm.showModal($element.attr('data-href'));
				});
			});
		}
	},

	getCurrentSlide: function() {
		return scForm.$slider.find('[data-slide=' + scForm.current + ']');
	},

	formLoaded: function() {
		if (scForm.$slider) {
			scForm.checkConditions.call(scForm.getCurrentSlide());
			scForm.$primaryForm.trigger('form-loaded');
		}
	},

	appendThankyouIframe: function() {
		//Required to be loaded before leadpoint!!
		$('body').append($('<div/>').attr('id', 'sc-thank-you-page').append('<iframe name="thankyou-iframe">'));
	},

	initializeGeoIp: function() {
		if (geoip2) {
			geoip2.city(scForm.replaceLocationVars, function(e) {
				console.error(e);
				scForm.replaceLocationVars(false);
			});
		}
	},

	initializePageBlocks: function() {
		$('.page-block').each(function(i, e) { $(e).addClass('page-block-' + (i + 1)); });
	},

	initializeForms: function() {
		var $form = $('form');
		$form.each(function(i, element) {
			var $form = $(element).attr('onsubmit', "return false");
			var btnText = $form.find('button').html().replace(/\s/g, '');
			var questionSet = scQuestions[btnText];

			if (!questionSet) {
				return scFlash.displayError("No question set called `" + btnText + '` was found. See <a href="http://instapage-reference.suitedconnector.com/" target="_blank">the reference page</a> for a list of available question sets.');
			}

			site_config = $.extend(site_config, scQuestions[btnText + '_config'] || {});
			scForm.initializeSlider($form, questionSet);
			scForm.$popup = $form.closest('.popup').addClass('is-form-popup');
			scForm.isPopup = scForm.$popup.length;

			if (scForm.isPopup) {
				//Instapage blocks the scroll event, so we need to override that
				scForm.$popup.bind('mousewheel', function(event) {
					var $content = $(this).find('.modal-content');
					site_config.scrollTop($content.scrollTop() - event.originalEvent.wheelDelta);
				});

				$('.popup-link').on('click', function() {
					if (!scForm.$popup.hasClass('is-viewed') && $(this).attr('id').replace(/^link-/, '') === scForm.$popup.attr('id').replace(/^popup-/, '')) {
						//XXX: There is problem with the way instapage shows the popup that causes the first slide to be off center
						setTimeout(function() {
							scForm.show(1);

							setTimeout(function() {
								scForm.$slider.css('position', 'absolute');
								scForm.$slider.css('position', 'relative');
								scForm.$slider.show(10);
							}, 300);
						}, 300);

						scForm.$popup.addClass('is-viewed');
					}
				});
			}

			scForm.$block = $form
				.closest('.page-element-type-form')
				.addClass('sc-form is-primary')
				.closest('.page-block')
				.addClass('is-primary-block');
			scForm.$border = scForm.$block.children('.border-holder');

			//Only render first form
			return false;
		});
	},

	getVertical: function() {
		// The vertical can either be defined in the site_config directly, or via an input in the form
		var verticalInputName = base64_encode('vertical'),
			$verticalInput = $('input[name="' + verticalInputName + '"]'),
			vertical = 'mortgage';
		if ($verticalInput.length > 0) {
			vertical = $verticalInput.val();
		} else if (site_config && site_config.cake && site_config.cake.vertical && Object.keys(site_config.cake.vertical).length) {
			// Let's arbitrarily use the first vertical defined in site_config
			for (var v in site_config.cake.vertical) {
				vertical = v;
				break;
			}
		}
		return vertical.toLowerCase();
	},

	appendDisclosureToForm: function() {
		if (scForm.$primaryForm) {
			scForm.$disclosure = $('<div/>').addClass('sc-disclosure');
			scForm.$primaryForm.append(scForm.$disclosure);
			var vertical = scForm.getVertical();
			if (vertical) {
				content = scCompliance.disclosures.form[vertical];
				if (content) {
					scForm.$disclosure.html(content);
					scForm.$disclosure.find('a.privacy-policy').click(function() {
						scForm.showModal(site_config.privacyUrl);
					});
				} else if (scCompliance.disclosures.leadpoint[vertical] === true) {
					scForm.appendLeadpointDisclosure();
				} else {
					scFlash.displayError("No form disclosure has been defined for the '" + vertical + "' vertical");
				}
			} else {
				scFlash.displayError("No vertical has been defined. Add a hidden input to the form called 'vertical' and add the vertical for your form (Auto / mortgage / personal etc.)");
			}
		}
	},

	populateFooterLinks: function() {
		// The footer links and copyright are automatically populated into any paragraph element containing the word
		// FOOTER
		var vertical = scForm.getVertical();
		var html = scCompliance.footerLinks[vertical];
		if (!html) html = genericCompliance.footerLinks;
		var $element = $('div:contains("FOOTER_LINKS")').find('p:contains("FOOTER_LINKS")'),
			$innerBlock = $element.closest('.block-inner'),
			$contents = $element.closest('.contents'),
			$widgetContainer = $element.closest('.widget-container'),
			$parentBlock = $element.closest('.page-block'),
			$nextBlock = $parentBlock.next('.page-block');

		if ($element.length) {
			$element.html(html);

			// Set the width first...
			var footerWidth = $innerBlock.width();

			$contents.width(footerWidth);
			$widgetContainer.width(footerWidth);
			$widgetContainer.css('left', '0px');
			$innerBlock.width(footerWidth);

			// Then set the height
			var footerHeight = $element.offset().top + $element.height() - $parentBlock.offset().top + 20;
			$element.height(footerHeight);
			$parentBlock.height(footerHeight);
			$parentBlock.children().height(footerHeight);

			// Make popup links work properly
			scForm.initializeFooterModalLinks();
		}
	},

	appendLeadpointDisclosure: function() {
		//By setting the srDisclosure, leadpoint will automatically fill in the disclosure agreement in this element.
		scForm.$disclosure.attr('id', 'srDisclosure');
	},

	populateFooterDisclosure: function() {
		// The footer disclosure is automatically populated into any paragraph element containing the word
		// DISCLOSURE
		var vertical = scForm.getVertical();
		if (vertical) {
			var disclaimerHtml = scCompliance.disclosures.footer[vertical];
			if (disclaimerHtml) {
				var $disclosure = $('div:contains("DISCLOSURE")').find('p:contains("DISCLOSURE")'),
					$innerBlock = $disclosure.closest('.block-inner'),
					$contents = $disclosure.closest('.contents'),
					$widgetContainer = $disclosure.closest('.widget-container'),
					$parentBlock = $disclosure.closest('.page-block'),
					$nextBlock = $parentBlock.next('.page-block');

				// If the element containing the word DISCLOSURE also contains the word ADVERTORIAL, then prepend the
				// advertorial footer disclosure.
				if ($disclosure.html().match('ADVERTORIAL')) {
					$disclosure.html(scCompliance.disclosures.footer.advertorial);
				} else {
					// Otherwise, clear the existing HTML
					$disclosure.html('');
				}

				$disclosure.append(disclaimerHtml);

				// Set the width first...
				var footerWidth = $innerBlock.width();

				$contents.width(footerWidth);
				$widgetContainer.width(footerWidth);
				$widgetContainer.css('left', '0px');
				$innerBlock.width(footerWidth);

				// Then set the height
				var footerHeight = $disclosure.offset().top + $disclosure.height() - $parentBlock.offset().top + 20;
				$disclosure.height(footerHeight);
				$parentBlock.height(footerHeight);
				$parentBlock.children().height(footerHeight);
			} else {
				scFlash.displayError("No footer disclosure has been defined for the '" + vertical + "' vertical");
			}
		} else {
			scFlash.displayError("No vertical has been defined. Add a hidden input to the form called 'vertical' and add the vertical for your form (Auto / mortgage / personal etc.)");
		}
	},

	initializeCompliance: function() {
		// Add custom disclosure after the submit button on the form
		scForm.appendDisclosureToForm();

		// Add disclaimer text to the bottom of the page
		scForm.populateFooterDisclosure();

		// Add footer links
		scForm.populateFooterLinks();
	},

	initializeSlider: function($form, questionSet) {
		scForm.$slider = scForm.buildSlider.call($form);

		//Add Slides to slider
		for (var step in questionSet) {
			if (questionSet.hasOwnProperty(step)) {
				var $content = $('<div/>').addClass('sc-slide-content'),
				$slide = $('<div/>').addClass('sc-slide').attr('data-slide', step);

				for (var name in questionSet[step]) {
					if (questionSet[step].hasOwnProperty(name)) {
						var field = $.extend(site_config.fields[name] || {}, questionSet[step][name]);
						var $holder = scForm.buildInput(name, field);
						$content.append($holder);

						if (field.separator) {
							$holder.before($('<div/>').addClass('sc-separator').html(field.separator));
						}

						if (field.condition) {
							if (!scForm.fieldConditions[step]) scForm.fieldConditions[step] = {};
							scForm.fieldConditions[step][name] = field.condition;
						}
					}
				}

				$slide.append($content);
				scForm.$slider.append($slide);
				scForm.total = Math.max(scForm.total, parseInt(step));
			}
		}
	},

	addBodyClass: function() {
		if (site_config.bodyClass) $('body').addClass(site_config.bodyClass);
	},

	initializeLogo: function() {
		site_config.logo = $('.page-block:first img').attr('src');
		var $pb = $('.page-block:first .cropped').css('background-image');
		if (!site_config.logo && typeof($pb) !== 'undefined' && $pb.length) {
			site_config.logo = $pb.match(/https?:[^)"]*/)[0];
		}
	},

	initializeTrustedForm: function() {
		if (site_config.trustedForm) {
			//General Use
			var z = document.getElementsByTagName('script')[0];

			//Trusted Form
			var field = 'xxTrustedFormCertUrl';
			var provideReferrer = false;
			var tf = document.createElement('script');
			tf.type = 'text/javascript';
			tf.async = true;
			tf.src = '//api.trustedform.com/trustedform.js?provide_referrer=' + escape(provideReferrer) + '&field=' + escape(field) + '&l=' + new Date().getTime() + Math.random();
			z.parentNode.insertBefore(tf, z);
		}
	},

	getInputValue: function(name) {
		var inputName = base64_encode(name),
			$input = $('input[name="' + inputName + '"]');
		return $input.val();
	},

	setCakeParamsInSiteConfig: function() {
		// Cake params can be set in inputs in the form, or directly in the site_config via JS.
		// If they're set in inputs, this function writes the values to the site_config object.
		var vertical = scForm.getInputValue('vertical'),
			ckmOfferId = scForm.getInputValue('ckm_offer_id'),
			affiliateParam = scForm.getInputValue('cake_param_a'),
			creativeParam = scForm.getInputValue('cake_param_c'),
			revSharing = scForm.getInputValue('rev_sharing');

		// Make sure the necessary site_config paths are present
		if (!site_config.cake) site_config.cake = {};
		if (!site_config.cake.vertical) site_config.cake.vertical = {};
		if (!site_config.cake.params) site_config.cake.params = {};
		if (vertical && !site_config.cake.vertical[vertical]) site_config.cake.vertical[vertical] = {};
		if (vertical && ckmOfferId) site_config.cake.vertical[vertical].ckm_offer_id = ckmOfferId;
		if (vertical && revSharing) site_config.cake.vertical[vertical].rev_sharing = revSharing;
		if (affiliateParam) site_config.cake.params.a = affiliateParam;
		if (creativeParam) site_config.cake.params.c = creativeParam;
	},

	initializeCakeMarketing: function() {
		//Cake Offer ID
		var ckm_offer_id = getQueryParam('ckm_offer_id'), v;

		if (ckm_offer_id !== null) {
			if (site_config.cake.posts) {
				for (v in site_config.cake.posts) {
					if (site_config.cake.posts.hasOwnProperty(v)) {
						site_config.cake.posts[v].ckm_offer_id = ckm_offer_id;
					}
				}
			} else if (site_config.cake.vertical) {
				for (v in site_config.cake.vertical) {
					if (site_config.cake.vertical.hasOwnProperty(v)) {
						site_config.cake.vertical[v].ckm_offer_id = ckm_offer_id;
					}
				}
			}
		}

		//Cake Request ID
		site_config.request_id = getQueryParam('req_id');
		if (site_config.request_id) {
			scForm.initSignup();
		} else {
			var url = "//suited45trk.com/?cp=js&" + $.param(site_config.cake.params);

			$.getScript(url).done(function() {
				$.post(site_config.apiUrl, {
					logFile: 'instapage_req_id',
					logMessage: site_config.pageLoadId + ' SUCCESS: ' + ckm_request_id + ' ckm_request_id was resolved:' + url
				});

				site_config.request_id = ckm_request_id;
				if (site_config.request_id) {
					scForm.initSignup();
				} else {
					$.post(site_config.apiUrl, {
						logFile: 'instapage_req_id',
						logMessage: site_config.pageLoadId + ' FAILED: NO ckm_request_id found: ' + url
					});
				}
			}).fail(function() {
				$.post(site_config.apiUrl, {
					logFile: 'instapage_req_id',
					logMessage: site_config.pageLoadId + ' FAILED: loading cake script: ' + url
				});
			});
		}
	},

	logRequestId: function() {
		$.post(site_config.apiUrl, {
			logFile: 'instapage_req_id',
			logMessage: site_config.pageLoadId + ' REQUEST_ID: ' + site_config.request_id
		});
	},

	initializeLeadpoint: function() {
		//LeadPoint
		dataLayer = [{
			a: '35209',
			i: '17734'
		}];

		(function(w, d, s, l, i) {
			w[l] = w[l] || [];
			w[l].push({
				'gtm.start': new Date().getTime(), event: 'gtm.js'
			});
			var f = d.getElementsByTagName(s)[0],
				j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
			j.async = true;
			j.src =
				'//www.googletagmanager.com/gtm.js?id=' + i + dl;
			f.parentNode.insertBefore(j, f);
		})(window, document, 'script', 'dataLayer', 'GTM-KCMVZ6');
	},

	initializeSrToken: function() {
		// This value is provided by Leadpoint when their script populates the disclosure.
		$('form').append('<input type="hidden" name="SR_TOKEN" value="">')
	},

	initialize: function() {
		// The order these run in is important!
		scForm.setCakeParamsInSiteConfig();
		scForm.initializeForms();
		scForm.initializeSrToken();
		scForm.appendThankyouIframe();
		scForm.configureSite();
		scForm.initializeCompliance();
		scForm.initializeGeoIp();
		scForm.initializePageBlocks();
		scForm.addBodyClass();
		scForm.initializeLogo();
		scForm.initializeTrustedForm();
		scForm.initializeCakeMarketing();
		scForm.logRequestId();
		scForm.initializeLeadpoint();
		scForm.formLoaded();
	}
};

(function() { scForm.initialize(); })();
