scFlash = {
	getFlashElement: function() {
		return $('.flash');
	},

	getOrBuildFlash: function() {
		var $flash = scFlash.getFlashElement();
		if ($flash.length > 0) {
			return $flash;
		} else {
			return scFlash.buildFlash();
		}
	},

	buildFlash: function() {
		$('body').prepend('<div class="flash"><span class="flash-message"></span><a href="#" class="dismiss-flash">x</a></div>');
		$('a.dismiss-flash').on('click', scFlash.hideFlash);
		return scFlash.getFlashElement();
	},

	hideFlash: function() {
		scFlash.getFlashElement().hide();
	},

	displayError: function(error) {
		var $flash = scFlash.getOrBuildFlash();
		$flash.addClass('flash-error');
		$flash.find('span.flash-message').html(error);
		$flash.show();
	},
}