scQuestions.rent2ownqualifiedrentbyowner = {
      1: {
        zip_code_initial: {
        	title:    '<span class="enter-zip">Enter your Zip Code</span> to Search for Listings',
          	helper:  '',
            attr:     {
              placeholder:  '',
            }
        }
      },
      2: {
        property_type: {
        	helper:  '',
        }
      },
      3: {
        credit_rating: {
        	helper:  '',
        }
      },
      4: {
        opt_served_military: {
          title:    'Have you or your spouse served in the military?<br><span>Veterans may be eligible for special loan programs.</span>',
        	helper:  '',
        }
      },
      5: {
		first_name: {},
		last_name:  {},
		address:    {},
		city:       {},
		state:      {},
		zipcode:    {},
        email:		{},
        phone_primary: {},
      }
};
