scQuestions.mortgage = {
	1:  {
		loan_purpose: {
			options: {
				'Refinance': "Refinance",
				'Purchase':  "Purchase"
			}
		}
	},
	2:  {
		property_type: {}
	},
	3:  {
		credit_rating: {}
	},
	4:  {
		zip_code_initial: {}
	},
	5:  {
		mortgage_amount: {
			condition: {loan_purpose: 'Refinance'}
		},
		own_rent:        {
			condition: {loan_purpose: 'Purchase'}
		}
	},
	6:  {
		est_property_value: {
			condition: {loan_purpose: 'Refinance'}
		},
		est_purchase_price: {
			condition: {loan_purpose: 'Purchase'}
		}
	},
	7:  {
		mortgage_rate:          {
			condition: {loan_purpose: 'Refinance'}
		},
		estimated_down_payment: {
			type:      'slider',
			condition: {loan_purpose: 'Purchase'}
		}
	},
	8:  {
		agent_found:       {
			condition: {loan_purpose: 'Purchase'},
		},
		current_loan_type: {
			condition: {loan_purpose: 'Refinance'}
		}
	},
	9:  {
		home_found: {
			condition: {loan_purpose: 'Purchase'}
		}
	},
	10: {
		property_zip: {
			condition: {loan_purpose: 'Purchase'}
		}
	},
	11: {
		moving_date: {
			condition: {loan_purpose: 'Purchase'}
		}
	},
	12: {
		opt_served_military: {}
	},
	13: {
		first_name: {},
		last_name:  {},
		address:    {},
		city:       {},
		state:      {},
		zipcode:    {}
	},
	14: {
		email:         {},
		phone_primary: {},
		phone_email_cert: {}
	}
};
