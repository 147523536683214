scQuestions.auto_single_dtx = {
	1: {
		loan_purpose:       {
			separator: '<h1><i class="fa fa-car"> Loan Information </h1>',
			title:     'Type of Loan',
			type:      'select',
			default:   '',
			helper:    false,
			options:   {
				'':          '(Select Loan Purpose)',
				'Refinance': 'Refinance your car',
				'Purchase':  'Purchase a car'
			},
			onBuild:   function() {
				buildDtxIframe();
			},
			onChange:  function() {
				if (this.val() === 'Purchase') {
					showDtxIframe();
					scForm.resize();
				}
			}
		},
		vehicle_make:       {
			title: 'Vehicle Make'
		},
		vehicle_model:      {
			title: 'Vehicle Model'
		},
		vehicle_year:       {
			title: 'Vehicle Year'
		},
		rate:               {
			title:     'Select your current interest rate',
			type:      'slider',
			default:   5.00,
			condition: {
				loan_purpose: 'Refinance'
			},
			css:       {
				margin: '1em auto'
			},
			options:   scOptions.autoRates
		},
		loan_balance:       {
			title:     'What is your current loan balance?',
			type:      'text',
			condition: {
				loan_purpose: 'Refinance'
			},
			class:     'is-currency',
			attr:      {
				placeholder: 'Current Balance'
			}
		},
		payment:            {
			title:     'What is your current car payment?',
			type:      'text',
			condition: {
				loan_purpose: 'Refinance'
			},
			attr:      {
				placeholder: 'Current Payment'
			}
		},
		credit_rating:      {
			type: 'slider'
		},
		first_name:         {
			separator: '<h1><i class="fa fa-user"> Personal Information</h1>',
			title:     'First Name:'
		},
		last_name:          {
			title: 'Last Name:'
		},
		address:            {
			title: 'Address:'
		},
		city:               {
			title: 'City:'
		},
		state:              {
			title: 'State:'
		},
		zip_code:           {
			title: 'Zip Code:'
		},
		email:              {
			title: 'Email:',
			validate: scValidate.email
		},
		phone_primary:      {
			title: 'Phone:'
		}
	}
};
