scQuestions.mortgage_2step_A = {
	1: {
		loan_purpose:           {
			separator: '<h1><i class="fa fa-home"></i> Mortgage Information</h1>',
			helper:    '',
			default:   'Refinance',
			options:   {
				'Purchase':  'Purchase',
				'Refinance': 'Refinance'
			}
		},
		property_type:          {
			type: 'select'
		},
		mortgage_amount:        {
			condition: {loan_purpose: 'Refinance'}
		},
		own_rent:               {
			condition: {loan_purpose: 'Purchase'}
		},
		est_property_value:     {
			condition: {loan_purpose: 'Refinance'}
		},
		est_purchase_price:     {
			condition: {loan_purpose: 'Purchase'}
		},
		mortgage_rate:          {
			condition: {loan_purpose: 'Refinance'}
		},
		estimated_down_payment: {
			condition: {loan_purpose: 'Purchase'}
		},
		agent_found:            {
			condition: {loan_purpose: 'Purchase'},
		},
		current_loan_type:      {
			condition: {loan_purpose: 'Refinance'}
		},
		home_found:             {
			condition: {loan_purpose: 'Purchase'}
		},
		property_zip:           {
			condition: {loan_purpose: 'Purchase'}
		},
		moving_date:            {
			condition: {loan_purpose: 'Purchase'}
		},
		credit_rating:          {
			type:  'slider',
			title: 'Rate Your Credit:'
		},
		opt_served_military:    {},

	},
	2: {
		first_name:    {
			separator: '<h1><i class="fa fa-user"></i> Personal Information</h1>',
			title:     'First Name:'
		},
		last_name:     {
			title: 'Last Name:'
		},
		address:       {
			title: 'Address:'
		},
		city:          {
			title: 'City:'
		},
		state:         {
			title: 'State:'
		},
		zipcode:       {
			title: 'Zip Code:'
		},
		email:         {
			title: 'Email:'
		},
		phone_primary: {
			title: 'Phone:'
		}
	}
};

scQuestions.mortgage_2step_A_config = {
	controlsAtTop:    true,
	helperAfterTitle: true,
	progressBar:      false,
	submitText:       'Looks Good!'
};
