scAutoMakeAndModelData = {
  "Acura": [
    "CL",
    "Integra",
    "Legend",
    "MDX",
    "NSX",
    "RDX",
    "RL",
    "RSX",
    "SLX",
    "TL",
    "TSX",
    "Vigor",
    "ZDX"
  ],
  "Alfa": [
    "Romeo 164",
    "Romeo Spider"
  ],
  "Aptera": [
    "2e",
    "Typ-1",
    "Type-1h"
  ],
  "Aston": [
    "Martin DB9",
    "Martin DB9 Volante",
    "Martin DBS",
    "Martin Rapide",
    "Martin V12 Vantage",
    "Martin V8 Vantage",
    "Martin V8 Vantage S",
    "Martin Vanquish S",
    "Martin Vantage",
    "Martin Virage"
  ],
  "Audi": [
    "100",
    "200",
    "4000",
    "4000CS Quattro",
    "4000s",
    "4000s Quattro",
    "5000CS",
    "5000CS Quattro",
    "5000S",
    "80",
    "80/90",
    "90",
    "A3",
    "A4",
    "A5",
    "A6",
    "A7",
    "A8",
    "Allroad",
    "Cabriolet",
    "Coupe GT",
    "Coupe Quattro",
    "Q5",
    "Q7",
    "Quattro",
    "R8",
    "riolet",
    "RS 4",
    "RS 6",
    "RS4",
    "RS6",
    "S4",
    "S5",
    "S6",
    "S8",
    "TT",
    "V8"
  ],
  "Austin": [
    "Mini",
    "Mini Cooper",
    "Mini Cooper S"
  ],
  "Bentley": [
    "Arnage",
    "Azure",
    "Azure T",
    "Brooklands",
    "Continental",
    "Continental Flying Spur",
    "Continental GT",
    "Continental GTC",
    "Continental Super",
    "Mulsanne"
  ],
  "BMW": [
    "1 Series",
    "3 Series",
    "325",
    "330",
    "5 Series",
    "525",
    "530",
    "545",
    "550",
    "6 Series",
    "600",
    "645",
    "650",
    "7 Series",
    "745",
    "750",
    "760",
    "8 Series",
    "Alpina B7",
    "M",
    "M Roadster",
    "M3",
    "M5",
    "M6",
    "X3",
    "X5",
    "X5 M",
    "X6",
    "X6 M",
    "Z3",
    "Z4",
    "Z4 M",
    "Z4 M Roadster",
    "Z8"
  ],
  "Bugatti": [
    "Veyron"
  ],
  "Buick": [
    "Century",
    "Coachbuilder",
    "Electra",
    "Enclave",
    "Estate",
    "Hearse",
    "LaCrosse",
    "LeSabre",
    "Lucerne",
    "Park Avenue",
    "Rainier",
    "Reatta",
    "Regal",
    "Rendezvous",
    "Riviera",
    "Roadmaster",
    "Skyhawk",
    "Skylark",
    "Somerset",
    "Special",
    "Terraza",
    "Verano"
  ],
  "Cadillac": [
    "Allante",
    "Brougham",
    "Catera",
    "CTS",
    "CTS-V",
    "DeVille",
    "DTS",
    "Eldorado",
    "Escalade",
    "Escalade ESV",
    "Escalade EXT",
    "Fleetwood",
    "Seville",
    "Sixty Special",
    "SRX",
    "STS",
    "STS-V",
    "XLR",
    "XLR-V"
  ],
  "Chevrolet": [
    "1500",
    "2500",
    "3500",
    "APV",
    "Astro",
    "Avalanche 1500",
    "Avalanche",
    "Avalanche 2500",
    "Aveo",
    "Bel Air",
    "Beretta",
    "Blazer",
    "Camaro",
    "Caprice",
    "Caprice Classic",
    "Cavalier",
    "Citation",
    "Classic",
    "Cobalt",
    "Cobalt SS",
    "Colorado",
    "Corsica",
    "Corvair",
    "Corvair 500",
    "Corvette",
    "Cruze",
    "Equinox",
    "Express 1500",
    "Express",
    "Express 2500",
    "Express 3500",
    "G-Series 1500",
    "G-Series 2500",
    "G-Series 3500",
    "G-Series G10",
    "G-Series G20",
    "G-Series G30",
    "HHR",
    "HHR Panel",
    "Impala",
    "Impala SS",
    "K5 Blazer",
    "Lumina",
    "Lumina APV",
    "LUV",
    "Malibu",
    "Malibu Maxx",
    "Metro",
    "Monte Carlo",
    "Monza",
    "Prizm",
    "S10",
    "S10 Blazer",
    "Silverado 1500",
    "Silverado",
    "Silverado 2500",
    "Silverado 3500",
    "Silverado 3500HD",
    "Silverado Hybrid",
    "Sonic",
    "Sportvan G10",
    "Sportvan G20",
    "Sportvan G30",
    "SSR",
    "Suburban 1500",
    "Suburban",
    "Suburban 2500",
    "Tahoe",
    "Tracker",
    "Trailblazer",
    "TrailBlazer",
    "Traverse",
    "Uplander",
    "Vega",
    "Venture",
    "Volt"
  ],
  "Chrysler": [
    "200",
    "300",
    "300C",
    "300M",
    "Aspen",
    "Cirrus",
    "Concorde",
    "Crossfire",
    "Crossfire Roadster",
    "Fifth Ave",
    "Grand Voyager",
    "Imperial",
    "LeBaron",
    "LHS",
    "New Yorker",
    "Pacifica",
    "Prowler",
    "PT Cruiser",
    "Sebring",
    "Town & Country",
    "Voyager"
  ],
  "Citroën": [
    "2CV",
    "CX",
    "SM"
  ],
  "Corbin": [
    "Sparrow"
  ],
  "Daewoo": [
    "Lanos",
    "Leganza",
    "Nubira"
  ],
  "Daihatsu": [
    "Charade",
    "Rocky"
  ],
  "Dodge": [
    "Aries",
    "Aspen",
    "Avenger",
    "Caliber",
    "Caravan",
    "Challenger",
    "Charger",
    "Colt",
    "D150",
    "D150 Club",
    "D250",
    "D250 Club",
    "D350",
    "D350 Club",
    "Dakota",
    "Dakota Club",
    "Daytona",
    "Durango",
    "Dynasty",
    "Grand Caravan",
    "Intrepid",
    "Journey",
    "Magnum",
    "Monaco",
    "Neon",
    "Nitro",
    "Omni",
    "Ram 1500",
    "Ram 1500 Club",
    "Ram",
    "Ram 2500",
    "Ram 2500 Club",
    "Ram 3500",
    "Ram 3500 Club",
    "Ram 50",
    "Ram Van 1500",
    "Ram Van 2500",
    "Ram Van 3500",
    "Ram Van B150",
    "Ram Van B250",
    "Ram Van B350",
    "Ram Wagon B150",
    "Ram Wagon B250",
    "Ram Wagon B350",
    "Ramcharger",
    "Shadow",
    "Spirit",
    "Sprinter",
    "Stealth",
    "Stratus",
    "Viper",
    "Viper RT/10"
  ],
  "Eagle": [
    "Premier",
    "Summit",
    "Talon",
    "Vision"
  ],
  "Fairthorpe": [
    "Rockette"
  ],
  "Ferrari": [
    "430 Scuderia",
    "458 Italia",
    "599 GTB Fiorano",
    "612 Scaglietti",
    "California",
    "F430",
    "F430 Spider",
    "FF"
  ],
  "FIAT": [
    "500"
  ],
  "Fiat": [
    "Nuova 500"
  ],
  "Fillmore": [
    "Fillmore"
  ],
  "Foose": [
    "Hemisfear"
  ],
  "Ford": [
    "Aerostar",
    "Aspire",
    "Bronco",
    "Bronco II",
    "C-MAX Hybrid",
    "Club Wagon",
    "Contour",
    "Country",
    "Courier",
    "Crown Victoria",
    "E-350 Super Duty",
    "E-350 Super Duty Van",
    "E-Series",
    "E150",
    "E250",
    "E350",
    "Econoline E150",
    "Econoline E250",
    "Econoline E350",
    "Edge",
    "Escape",
    "Escort",
    "Escort ZX2",
    "Excursion",
    "EXP",
    "Expedition",
    "Expedition EL",
    "Explorer",
    "Explorer Sport",
    "Explorer Sport Trac",
    "F-250 Super Duty",
    "F-350 Super Duty",
    "F-Series",
    "F-Series Super Duty",
    "F150",
    "F250",
    "F350",
    "F450",
    "Fairlane",
    "Falcon",
    "Festiva",
    "Fiesta",
    "Five Hundred",
    "Flex",
    "Focus",
    "Focus ST",
    "Freestar",
    "Freestyle",
    "Fusion",
    "Galaxie",
    "GT",
    "GT500",
    "Laser",
    "Lightning",
    "LTD",
    "LTD Crown Victoria",
    "Model T",
    "Mustang",
    "Probe",
    "Ranger",
    "Taurus",
    "Taurus X",
    "Tempo",
    "Th!nk",
    "TH!NK",
    "Thunderbird",
    "Torino",
    "Transit Connect",
    "Windstar",
    "ZX2"
  ],
  "Geo": [
    "Metro",
    "Prizm",
    "Storm",
    "Tracker"
  ],
  "GMC": [
    "1500",
    "1500 Club Coupe",
    "2500",
    "2500 Club Coupe",
    "3500",
    "3500 Club Coupe",
    "Acadia",
    "Canyon",
    "Envoy",
    "Envoy XL",
    "Envoy XUV",
    "EV1",
    "Jimmy",
    "Rally Wagon 1500",
    "Rally Wagon 2500",
    "Rally Wagon 3500",
    "Rally Wagon G2500",
    "Rally Wagon G3500",
    "Safari",
    "Savana 1500",
    "Savana",
    "Savana 2500",
    "Savana 3500",
    "Savana Cargo Van",
    "Sierra 1500",
    "Sierra",
    "Sierra 2500",
    "Sierra 2500HD",
    "Sierra 3500",
    "Sierra 3500HD",
    "Sierra Denali",
    "Sierra Hybrid",
    "Sonoma",
    "Sonoma Club",
    "Sonoma Club Coupe",
    "Suburban 1500",
    "Suburban 2500",
    "Terrain",
    "Vandura 1500",
    "Vandura 2500",
    "Vandura 3500",
    "Vandura G1500",
    "Vandura G2500",
    "Vandura G3500",
    "Yukon",
    "Yukon Denali",
    "Yukon XL 1500",
    "Yukon XL",
    "Yukon XL 2500"
  ],
  "Hillman": [
    "Minx Magnificent"
  ],
  "Holden": [
    "Monaro",
    "VS Commodore"
  ],
  "Honda": [
    "Accord",
    "Accord Crosstour",
    "Civic",
    "Civic GX",
    "Civic Si",
    "CR-V",
    "CR-X",
    "CR-Z",
    "Crosstour",
    "del Sol",
    "Del Sol",
    "Element",
    "FCX Clarity",
    "Fit",
    "Insight",
    "Odyssey",
    "Passport",
    "Pilot",
    "Prelude",
    "Ridgeline",
    "S2000"
  ],
  "HUMMER": [
    "H1",
    "H2",
    "H3",
    "H3T"
  ],
  "Hummer": [
    "H2",
    "H2 SUT",
    "H2 SUV",
    "H3"
  ],
  "Hyundai": [
    "Accent",
    "Azera",
    "Elantra",
    "Entourage",
    "Equus",
    "Excel",
    "Genesis",
    "Genesis Coupe",
    "HED-5",
    "Santa Fe",
    "Scoupe",
    "Sonata",
    "Tiburon",
    "Tucson",
    "Veloster",
    "Veracruz",
    "XG300",
    "XG350"
  ],
  "Infiniti": [
    "EX",
    "FX",
    "G",
    "G25",
    "G35",
    "G37",
    "I",
    "IPL G",
    "J",
    "JX",
    "M",
    "Q",
    "QX",
    "QX56"
  ],
  "Isuzu": [
    "Amigo",
    "Ascender",
    "Axiom",
    "Hombre",
    "Hombre Space",
    "i-280",
    "i-290",
    "i-350",
    "i-370",
    "i-Series",
    "Impulse",
    "Oasis",
    "Rodeo",
    "Rodeo Sport",
    "Space",
    "Stylus",
    "Trooper",
    "VehiCROSS"
  ],
  "Jaguar": [
    "S-Type",
    "X-Type",
    "XF",
    "XJ",
    "XJ Series",
    "XK",
    "XK Series"
  ],
  "Jeep": [
    "Cherokee",
    "Comanche",
    "Commander",
    "Compass",
    "Grand Cherokee",
    "Liberty",
    "Patriot",
    "Wrangler"
  ],
  "Jensen": [
    "Interceptor"
  ],
  "Kia": [
    "Amanti",
    "Borrego",
    "Carens",
    "Forte",
    "Mentor",
    "Mohave/Borrego",
    "Optima",
    "Rio",
    "Rio5",
    "Rondo",
    "Sedona",
    "Sephia",
    "Sorento",
    "Soul",
    "Spectra",
    "Spectra5",
    "Sportage"
  ],
  "Lamborghini": [
    "Aventador",
    "Countach",
    "Diablo",
    "Gallardo",
    "Murciélago",
    "Murciélago LP640",
    "Reventón"
  ],
  "Land": [
    "Rover Defender 110",
    "Rover Defender",
    "Rover Defender 90",
    "Rover Defender Ice Edition",
    "Rover Discovery",
    "Rover Discovery Series II",
    "Rover Freelander",
    "Rover LR2",
    "Rover LR3",
    "Rover LR4",
    "Rover Range Rover",
    "Rover Range Rover Classic",
    "Rover Range Rover Evoque",
    "Rover Range Rover Sport",
    "Rover Sterling"
  ],
  "Lexus": [
    "CT",
    "ES",
    "GS",
    "GX",
    "HS",
    "IS",
    "IS F",
    "IS-F",
    "LFA",
    "LS",
    "LS Hybrid",
    "LX",
    "RX",
    "RX Hybrid",
    "SC"
  ],
  "Lincoln": [
    "Aviator",
    "Blackwood",
    "Continental",
    "Continental Mark VII",
    "LS",
    "Mark LT",
    "Mark VII",
    "Mark VIII",
    "MKS",
    "MKT",
    "MKX",
    "MKZ",
    "Navigator",
    "Navigator L",
    "Town Car",
    "Zephyr"
  ],
  "Lotus": [
    "Elan",
    "Elise",
    "Esprit",
    "Esprit Turbo",
    "Evora",
    "Exige"
  ],
  "Maserati": [
    "228",
    "430",
    "Biturbo",
    "Coupe",
    "Gran Sport",
    "GranSport",
    "GranTurismo",
    "Karif",
    "Quattroporte",
    "Spyder"
  ],
  "Maybach": [
    "57",
    "57S",
    "62",
    "Landaulet"
  ],
  "Mazda": [
    "323",
    "626",
    "929",
    "B-Series",
    "B-Series Plus",
    "B2000",
    "B2500",
    "B2600",
    "CX-5",
    "CX-7",
    "CX-9",
    "Familia",
    "GLC",
    "Mazda2",
    "Mazda3",
    "Mazda5",
    "Mazda6",
    "MAZDA6",
    "Mazda6 5-Door",
    "Mazda6 Sport",
    "Mazdaspeed 3",
    "Mazdaspeed6",
    "Miata MX-5",
    "Millenia",
    "MPV",
    "MX-3",
    "MX-5",
    "MX-6",
    "Navajo",
    "Protege",
    "Protege5",
    "RX-7",
    "RX-8",
    "Tribute"
  ],
  "McLaren": [
    "MP4-12C"
  ],
  "Mercedes-Benz": [
    "190E",
    "300CE",
    "300D",
    "300E",
    "300SD",
    "300SE",
    "300SL",
    "300TE",
    "400E",
    "400SE",
    "400SEL",
    "500E",
    "500SEC",
    "500SEL",
    "500SL",
    "600SEC",
    "600SEL",
    "600SL",
    "C-Class",
    "CL-Class",
    "CL65 AMG",
    "CLK-Class",
    "CLS-Class",
    "E-Class",
    "G-Class",
    "G55 AMG",
    "GL-Class",
    "GLK-Class",
    "M-Class",
    "R-Class",
    "S-Class",
    "SL-Class",
    "SL65 AMG",
    "SLK-Class",
    "SLK55 AMG",
    "SLR McLaren",
    "SLS AMG",
    "SLS-Class",
    "Sprinter",
    "Sprinter 2500",
    "Sprinter 3500",
    "W123",
    "W126",
    "W201"
  ],
  "Mercury": [
    "Capri",
    "Cougar",
    "Grand Marquis",
    "Lynx",
    "Marauder",
    "Mariner",
    "Marquis",
    "Milan",
    "Montego",
    "Monterey",
    "Mountaineer",
    "Mystique",
    "Sable",
    "Topaz",
    "Tracer",
    "Villager"
  ],
  "Merkur": [
    "XR4Ti"
  ],
  "MG": [
    "MGB"
  ],
  "MINI": [
    "Clubman",
    "Cooper",
    "Cooper Clubman",
    "Cooper Countryman",
    "Countryman",
    "MINI"
  ],
  "Mitsubishi": [
    "3000GT",
    "Challenger",
    "Chariot",
    "Cordia",
    "Diamante",
    "Eclipse",
    "Endeavor",
    "Excel",
    "Expo",
    "Expo LRV",
    "Galant",
    "GTO",
    "i-MiEV",
    "L300",
    "Lancer",
    "Lancer Evolution",
    "Mighty Max",
    "Mighty Max Macro",
    "Mirage",
    "Montero",
    "Montero Sport",
    "Outlander",
    "Outlander Sport",
    "Pajero",
    "Precis",
    "Raider",
    "RVR",
    "Sigma",
    "Space",
    "Starion",
    "Tredia",
    "Truck",
    "Tundra"
  ],
  "Morgan": [
    "Aero 8"
  ],
  "Nissan": [
    "200SX",
    "240SX",
    "280ZX",
    "300ZX",
    "350Z",
    "350Z Roadster",
    "370Z",
    "Altima",
    "Armada",
    "Cube",
    "cube",
    "Datsun/Nissan Z-car",
    "Frontier",
    "GT-R",
    "JUKE",
    "Leaf",
    "Maxima",
    "Murano",
    "NV1500",
    "NV2500",
    "NV3500",
    "NX",
    "Pathfinder",
    "Pathfinder Armada",
    "Quest",
    "Rogue",
    "Sentra",
    "Stanza",
    "Titan",
    "Versa",
    "Xterra"
  ],
  "Oldsmobile": [
    "88",
    "98",
    "Achieva",
    "Alero",
    "Aurora",
    "Bravada",
    "Ciera",
    "Custom Cruiser",
    "Cutlass",
    "Cutlass Cruiser",
    "Cutlass Supreme",
    "Intrigue",
    "LSS",
    "Regency",
    "Silhouette",
    "Toronado"
  ],
  "Panoz": [
    "Esperante"
  ],
  "Peugeot": [
    "207"
  ],
  "Plymouth": [
    "Acclaim",
    "Breeze",
    "Colt",
    "Colt Vista",
    "Fury",
    "Grand Voyager",
    "Horizon",
    "Laser",
    "Neon",
    "Prowler",
    "Reliant",
    "Roadrunner",
    "Sundance",
    "Volare",
    "Voyager"
  ],
  "Pontiac": [
    "1000",
    "6000",
    "Aztek",
    "Bonneville",
    "Chevette",
    "Daewoo Kalos",
    "Fiero",
    "Firebird",
    "Firebird Formula",
    "Firebird Trans Am",
    "Firefly",
    "G3",
    "G5",
    "G6",
    "G8",
    "Gemini",
    "Grand Am",
    "Grand Prix",
    "Grand Prix Turbo",
    "GTO",
    "LeMans",
    "Lemans",
    "Montana",
    "Montana SV6",
    "Monterey",
    "Parisienne",
    "Safari",
    "Solstice",
    "Sunbird",
    "Sunfire",
    "Tempest",
    "Torrent",
    "Trans Sport",
    "Turbo Firefly",
    "Vibe"
  ],
  "Porsche": [
    "911",
    "914",
    "924",
    "924 S",
    "928",
    "944",
    "968",
    "Boxster",
    "Carrera GT",
    "Cayenne",
    "Cayman",
    "Panamera"
  ],
  "Ram": [
    "1500",
    "2500",
    "3500",
    "C/V",
    "Dakota"
  ],
  "Rambler": [
    "Classic"
  ],
  "Renault": [
    "Alliance"
  ],
  "Rolls-Royce": [
    "Ghost",
    "Phantom"
  ],
  "Saab": [
    "9-2X",
    "9-3",
    "9-4X",
    "9-5",
    "9-7X",
    "900",
    "9000"
  ],
  "Saturn": [
    "Astra",
    "Aura",
    "Ion",
    "L-Series",
    "Outlook",
    "Relay",
    "S-Series",
    "Sky",
    "VUE",
    "Vue"
  ],
  "Scion": [
    "FR-S",
    "iQ",
    "tC",
    "xA",
    "xB",
    "xD"
  ],
  "Shelby": [
    "GT350",
    "GT500"
  ],
  "Smart": [
    "Fortwo"
  ],
  "Spyker": [
    "C8 Double 12 S",
    "C8 Laviolette",
    "C8 Spyder",
    "C8 Spyder Wide Body",
    "Cars C8"
  ],
  "Studebaker": [
    "Avanti"
  ],
  "Subaru": [
    "Alcyone SVX",
    "B9 Tribeca",
    "Baja",
    "Brat",
    "BRAT",
    "BRZ",
    "Forester",
    "Impreza",
    "Impreza WRX",
    "Justy",
    "Legacy",
    "Leone",
    "Loyale",
    "Outback",
    "Outback Sport",
    "SVX",
    "Tribeca",
    "XT"
  ],
  "Suzuki": [
    "Aerio",
    "Cultus",
    "Daewoo Lacetti",
    "Daewoo Magnus",
    "Equator",
    "Esteem",
    "Forenza",
    "Grand Vitara",
    "Kizashi",
    "Reno",
    "Samurai",
    "Sidekick",
    "SJ",
    "SJ 410",
    "Swift",
    "SX4",
    "Verona",
    "Vitara",
    "X-90",
    "XL-7",
    "XL7"
  ],
  "Tesla": [
    "Model S",
    "Roadster"
  ],
  "Toyota": [
    "4Runner",
    "Avalon",
    "Camry",
    "Camry Hybrid",
    "Camry Solara",
    "Celica",
    "Corolla",
    "Cressida",
    "Echo",
    "FJ Cruiser",
    "Highlander",
    "Highlander Hybrid",
    "Ipsum",
    "Land Cruiser",
    "Matrix",
    "MR2",
    "Paseo",
    "Previa",
    "Prius",
    "Prius c",
    "Prius Plug-in",
    "Prius Plug-in Hybrid",
    "Prius v",
    "RAV4",
    "Sequoia",
    "Sienna",
    "Solara",
    "Supra",
    "T100",
    "T100 Xtra",
    "Tacoma",
    "Tacoma Xtra",
    "Tercel",
    "Truck Xtracab SR5",
    "Tundra",
    "TundraMax",
    "Venza",
    "Xtra",
    "Yaris"
  ],
  "Volkswagen": [
    "Beetle",
    "Cabriolet",
    "CC",
    "Corrado",
    "Eos",
    "Eurovan",
    "Fox",
    "GLI",
    "Golf",
    "Golf III",
    "GTI",
    "Jetta",
    "Jetta III",
    "New Beetle",
    "Passat",
    "Phaeton",
    "Quantum",
    "R32",
    "Rabbit",
    "rio",
    "riolet",
    "Routan",
    "Scirocco",
    "Tiguan",
    "Touareg 2",
    "Touareg",
    "Type 2",
    "Vanagon"
  ],
  "Volvo": [
    "240",
    "740",
    "850",
    "940",
    "960",
    "C30",
    "C70",
    "S40",
    "S60",
    "S70",
    "S80",
    "S90",
    "V40",
    "V50",
    "V70",
    "V90",
    "XC60",
    "XC70",
    "XC90"
  ]
}
