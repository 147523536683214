(function() {
	prepopulator = {
		initialized: false,
        fieldValues: {},
        getFieldValuesFromQueryString: function() {
			var query = location.search.substr(1);
			var result = {};
			query.split("&").forEach(function(part) {
				var item = part.split("=");
				if (item[0]) result[item[0]] = decodeURIComponent(item[1]);
			});
			return result;
		},

		setFieldValue: function(fieldName, value) {
			var $field = $('[data-name=' + fieldName + ']');
			if ($field.length) {
				var fieldType = $field.attr('type');
				if (fieldType == 'radio' || fieldType == 'checkbox') {
					$('[data-name="' + fieldName + '"][value="' + value + '"]').attr('checked', 'checked');
				} else {
					$field.val(value);
				}

				// If this field has a formatting function defined, call it
				var fieldDefinition = site_config.fields[fieldName];
				if (fieldDefinition && fieldDefinition.format) {
					var element = $field.get(0);
					scForm.formatInput(element, fieldDefinition.format);
				}
			}
		},
		initialize: function() {
            if (window.xdc) {
                // check for cookie vals
                prepopulator.fieldValues = window.xdc.get();
            }
            var prepop = prepopulator.getFieldValuesFromQueryString();
            for (var key in prepop) {
                if (prepop.hasOwnProperty(key)) {
                    prepopulator.fieldValues[key] = prepop[key];
                }
            }
            prepopulator.initialized = true;
        },
		prepop: function() {
            if (!prepopulator.initialized) {
                prepopulator.initialize();
            }
			for (var fieldName in prepopulator.fieldValues) {
				if (prepopulator.fieldValues.hasOwnProperty(fieldName)) {
                    prepopulator.setFieldValue(fieldName, prepopulator.fieldValues[fieldName]);
                }
			}
		}
	};

    $('form').on('form-loaded', function() {
        // Arbitrary 1s wait to override the Instapage prepop
        // I HATE WHEN YOU MAKE ME ACT LIKE THIS, INSTAPAGE
        setTimeout(prepopulator.prepop, 1000);
    });

})();
